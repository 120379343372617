import React from 'react'
import ReactDOM from 'react-dom/client'

import '@/styles/global.css'
import '@fontsource/inter'

import { AuthProvider } from '@/context/auth.context'
import { BeeoinitiativeProvider } from '@/context/beeoinitiative-context'
import { MixpanelProvider } from '@/context/mixpanel.context'
import { PrintProvider } from '@/context/print-context'
import Route from '@/route'
import { QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { I18nextProvider } from 'react-i18next'

import { QUERY_CLIENT } from '@/lib/config/react-query'
import { initErrorTracking } from '@/lib/config/sentry'
import { getI18nInstance } from '@/lib/i18n'
import { Toaster } from '@/components/ui/toaster'
import { TailwindIndicator } from '@/components/tailwind-indicator'

if (import.meta.env.VITE_ENV === 'production' || import.meta.env.VITE_ENV === 'staging') {
  initErrorTracking(import.meta.env.VITE_SENTRY_DSN, import.meta.env.VITE_ENV)
}

ReactDOM.createRoot(document.getElementById('root')!).render(
  <React.StrictMode>
    <QueryClientProvider client={QUERY_CLIENT}>
      <ReactQueryDevtools initialIsOpen={false} />
      <I18nextProvider i18n={getI18nInstance()}>
        <AuthProvider>
          <MixpanelProvider>
            <PrintProvider>
              <BeeoinitiativeProvider>
                <Route />
                <Toaster />
                <TailwindIndicator />
              </BeeoinitiativeProvider>
            </PrintProvider>
          </MixpanelProvider>
        </AuthProvider>
      </I18nextProvider>
    </QueryClientProvider>
  </React.StrictMode>
)

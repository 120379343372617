import { useEffect, useState } from 'react'
import { User } from '@/client/backend/models/user'
import { i18nKeys } from '@/locales/keys'
import { ColumnDef } from '@tanstack/react-table'
import { useTranslation } from 'react-i18next'

import { Badge } from '@/components/ui/badge'
import { DataTable } from '@/components/data-table/data-table'
import { DataTableColumnHeader } from '@/components/data-table/data-table-column-header'
import UsersTableAction from '@/components/users-table-action'
import UsersTableRowAction from '@/components/users-table-row-action'

const labels = [
  {
    value: 'first_name',
    label: 'Firstname',
  },
  {
    value: 'last_name',
    label: 'Lastname',
  },
  {
    value: 'email',
    label: 'Email',
  },
]

type UsersTableProps = {
  data: User[]
  accountId?: number
}

const UsersTable = ({ data, accountId }: UsersTableProps) => {
  const { t } = useTranslation()

  const [users, setUsers] = useState<User[]>(data)

  useEffect(() => {
    setUsers(data)
  }, [data])

  const columns: ColumnDef<User>[] = [
    {
      accessorKey: 'id',
      header: ({ column }) => <DataTableColumnHeader column={column} children="" />,
      cell: ({ row }) => <div className="w-[80px]">{row.getValue('id')}</div>,
      enableSorting: false,
      enableHiding: false,
    },
    {
      accessorKey: 'first_name',
      header: ({ column }) => (
        <DataTableColumnHeader
          column={column}
          children={t(i18nKeys.global.settings.page.users.table.header.firstName)}
        />
      ),
      cell: ({ row }) => {
        const label = labels.find((label) => label.value === row.original.first_name)

        return (
          <div className="flex space-x-2">
            {label && <Badge variant="outline">{label.label}</Badge>}
            <span className="max-w-[500px] truncate font-medium">{row.getValue('first_name')}</span>
          </div>
        )
      },
    },
    {
      accessorKey: 'last_name',
      header: ({ column }) => (
        <DataTableColumnHeader
          column={column}
          children={t(i18nKeys.global.settings.page.users.table.header.lastName)}
        />
      ),
      cell: ({ row }) => {
        const label = labels.find((label) => label.value === row.original.last_name)

        return (
          <div className="flex space-x-2">
            {label && <Badge variant="outline">{label.label}</Badge>}
            <span className="max-w-[500px] truncate font-medium">{row.getValue('last_name')}</span>
          </div>
        )
      },
    },
    {
      accessorKey: 'email',
      header: ({ column }) => (
        <DataTableColumnHeader column={column} children={t(i18nKeys.global.settings.page.users.table.header.email)} />
      ),
      cell: ({ row }) => {
        const label = labels.find((label) => label.value === row.original.email)

        return (
          <div className="flex space-x-2">
            {label && <Badge variant="outline">{label.label}</Badge>}
            <span className="max-w-[1000px] truncate font-medium">{row.getValue('email')}</span>
          </div>
        )
      },
    },
    {
      accessorKey: 'groups.name',
      header: ({ column }) => <DataTableColumnHeader column={column} children={'group'} />,
      cell: ({ row }) => {
        return (
          <div className="flex space-x-2">
            {row.original.groups.map((group) => (
              <Badge key={group.id} variant="outline">
                {group.name}
              </Badge>
            ))}
          </div>
        )
      },
    },

    {
      id: 'actions',
      cell: ({ row }) => {
        return <UsersTableRowAction user={row.original} />
      },
    },
  ]

  return (
    <DataTable
      columns={columns}
      data={users}
      action={<UsersTableAction users={users} setUsers={setUsers} accountId={accountId} />}
      filterIdColumn="email"
    />
  )
}

export default UsersTable

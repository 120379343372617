import { BuildingTypeEnum } from '@/client/backend/models'
import { z as zod } from 'zod'

export const siteSchema = zod.object({
  name: zod.string().nonempty(),
  lat: zod.coerce.number().min(-90).max(90),
  lng: zod.coerce.number().min(-180).max(180),
  surface: zod.coerce.number().min(0),
  radius: zod.coerce.number().min(100).max(10000),
  type: zod.nativeEnum(BuildingTypeEnum),
  key: zod.string().optional(),
})

export type SiteFormValues = zod.infer<typeof siteSchema>

import {
  accountsRetrieve,
  accountsSitesList,
  accountsUsersList,
  getAccountsSitesListQueryKey,
  getAccountsUsersListQueryKey,
} from '@/client/backend/api/accounts/accounts'
import { i18nKeys } from '@/locales/keys'
import { useQuery } from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router'

import { toTitleCase } from '@/lib/string'
import { Separator } from '@/components/ui/separator'
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/ui/tabs'
import SettingsSitesTable from '@/components/settings-sites-table'
import UsersTable from '@/components/users-table'

const AccountDetailPage = () => {
  const { t } = useTranslation()
  const { accountId } = useParams()

  const { data: account } = useQuery({
    queryKey: accountId ? [accountsRetrieve(parseInt(accountId))] : [],
    queryFn: () => accountsRetrieve(parseInt(accountId!)),
    enabled: !!accountId,
  })

  const { data: sites } = useQuery({
    queryKey: accountId ? [getAccountsSitesListQueryKey(parseInt(accountId))] : [],
    queryFn: () => accountsSitesList(parseInt(accountId!)),
    enabled: !!accountId,
  })

  const { data: users } = useQuery({
    queryKey: accountId ? [getAccountsUsersListQueryKey(parseInt(accountId))] : [],
    queryFn: () => accountsUsersList(parseInt(accountId!)),
    enabled: !!accountId,
  })

  return (
    <Tabs defaultValue="sites">
      <TabsList>
        <TabsTrigger value="sites">Sites</TabsTrigger>
        <TabsTrigger value="users">Users</TabsTrigger>
      </TabsList>
      <TabsContent value="sites">
        <div className="space-y-6">
          <div>
            <h3 className="text-lg font-medium"> {account?.name ? toTitleCase(account?.name) : ''}'s sites</h3>
            <p className="text-sm text-muted-foreground">You will find the {account?.name} sites</p>
          </div>
          <Separator />
          <SettingsSitesTable data={sites?.features ?? []} accountId={account?.id} />
        </div>
      </TabsContent>
      <TabsContent value="users">
        <div className="space-y-6">
          <div>
            <h3 className="text-lg font-medium">{t(i18nKeys.global.settings.page.users.title)}</h3>
            <p className="text-sm text-muted-foreground">{t(i18nKeys.global.settings.page.users.description)}</p>
          </div>
          <Separator />
          <UsersTable data={users ?? []} accountId={account ? account.id : undefined} />
        </div>
      </TabsContent>
    </Tabs>
  )
}

export default AccountDetailPage

import { i18nKeys } from '@/locales/keys'
import { ResponsivePie } from '@nivo/pie'
import { useTranslation } from 'react-i18next'

import { CultureArea } from '@/types/culture-area'
import { calculatePercentage } from '@/lib/math'
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from '@/components/ui/card'

interface CulturesPieChartProps {
  cultures: CultureArea[]
}

const CulturesPieChart = ({ cultures }: CulturesPieChartProps) => {
  const { t } = useTranslation()

  const totalValue = cultures.reduce((acc, culture) => acc + culture.total_surface, 0)

  const pieData = cultures.map((culture) => ({
    id: culture.name,
    label: culture.name.slice(0, 80),
    value: calculatePercentage(culture.total_surface, totalValue),
  }))

  const tailwindSkyShades = [
    '#075985',
    '#0284c7',
    '#0ea5e9',
    '#38bdf8',
    '#bae6fd',
    '#e0f2fe',
    '#f0f9ff',
    '#f0f9ff',
    '#ffffff',
    '#ffffff',
    '#ffffff',
    '#ffffff',
    '#ffffff',
    '#ffffff',
    '#ffffff',
    '#ffffff',
    '#ffffff',
    '#ffffff',
    '#ffffff',
    '#ffffff',
    '#ffffff',
    '#ffffff',
    '#ffffff',
    '#ffffff',
  ]
  return (
    <Card className="mt-12 flex min-h-[80px] w-full min-w-0 flex-col">
      <CardHeader>
        <CardTitle> {t(i18nKeys.beeoimpact.pesticidesTable.cultures.pie.title)}</CardTitle>
        <CardDescription>{t(i18nKeys.beeoimpact.pesticidesTable.cultures.pie.description)}</CardDescription>
      </CardHeader>

      <CardContent className="h-96">
        <ResponsivePie
          startAngle={-180}
          sortByValue={true}
          margin={{ top: 20, right: 20, bottom: 40, left: 0 }}
          innerRadius={0.5}
          padAngle={0.7}
          cornerRadius={3}
          activeOuterRadiusOffset={2}
          borderWidth={1}
          data={pieData}
          valueFormat=">-0.1%"
          borderColor={{
            from: 'color',
            modifiers: [['darker', 0.2]],
          }}
          colors={tailwindSkyShades}
          arcLinkLabel="label"
          arcLinkLabelsStraightLength={2}
          arcLinkLabelsDiagonalLength={16}
          arcLinkLabelsSkipAngle={6}
          arcLinkLabelsTextColor="#333333"
          arcLinkLabelsThickness={5}
          arcLinkLabelsColor={{ from: 'color' }}
          arcLabelsSkipAngle={6}
          arcLinkLabelsTextOffset={5}
          arcLabelsTextColor={{
            from: 'color',
            modifiers: [['darker', 2]],
          }}
          fill={[
            {
              match: {
                id: 'ignored',
              },
              id: 'dots',
            },
          ]}
          defs={[
            {
              id: 'dots',
              type: 'patternDots',
              background: 'inherit',
              color: 'rgba(255, 255, 255, 0.3)',
              size: 4,
              padding: 1,
              stagger: true,
            },
            {
              id: 'lines',
              type: 'patternLines',
              background: 'inherit',
              color: 'rgba(255, 255, 255, 0.3)',
              rotation: -45,
              lineWidth: 6,
              spacing: 10,
            },
          ]}
        />
      </CardContent>
    </Card>
  )
}

export default CulturesPieChart

import { useState } from 'react'
import { ProbablePesticide } from '@/client/backend/models'
import { i18nKeys } from '@/locales/keys'
import { pesticidesLoader } from '@/pages/beeoimpact/pesticides-details/pesticides-details-page.loader'
import { useTranslation } from 'react-i18next'
import { useLoaderData } from 'react-router-dom'

import { groupCultures } from '@/lib/plots'
import { Routes } from '@/lib/routes/routes'
import { getPath } from '@/lib/routes/utils'
import { Card, CardContent } from '@/components/ui/card'
import { Separator } from '@/components/ui/separator'
import CulturesTable from '@/components/beeoimpact/impacts/cultures-table'
import CulturesPieChart from '@/components/beeoimpact/impacts/cultures-tree-map'
import CallToACtion from '@/components/beeoimpact/sites/cta'
import PesticidesTable from '@/components/beeoimpact/tables/pesticides-table'
import PesticidesTableMap from '@/components/beeoimpact/tables/pesticides-table-map'
import BreadcrumbComponent from '@/components/breadcrumb-component'
import { PageHeader, PageHeaderHeading } from '@/components/page-header'
import { H3 } from '@/components/typography'

const PesticidesDetailsPage = () => {
  const { t } = useTranslation()
  const { site, landplots, center } = useLoaderData() as Awaited<ReturnType<typeof pesticidesLoader>>

  const [selectedPlot] = useState<string>('')

  const [selectedPesticide, setSelectedPesticide] = useState<ProbablePesticide>({} as ProbablePesticide)

  const activeMatters = site.properties?.stats_pesticides?.probable_pesticides ?? []

  const cultures = groupCultures(landplots)

  return (
    <div className="container relative mt-8">
      <BreadcrumbComponent
        breadcrumbItems={[
          {
            label: t(i18nKeys.beeoimpact.dashboard.breadcrumb.beeoapps),
            href: '/',
          },
          {
            label: t(i18nKeys.beeoimpact.dashboard.breadcrumb.beeoimpact),
          },
          {
            label: t(i18nKeys.beeoimpact.common.breadcrumb.sites),
            href: getPath(Routes.BEEOIMPACT_SITES),
          },
          {
            label: site?.properties?.name ?? t(i18nKeys.beeoimpact.common.breadcrumb.site),
            href: getPath(Routes.BEEOIMPACT_SITE, { params: { siteId: site?.id } }),
          },
        ]}
        currentPage={t(i18nKeys.beeoimpact.common.breadcrumb.pesticide)}
      />

      <div className="mb-4 flex flex-col justify-between">
        <div className="flex flex-row justify-between">
          <PageHeader>
            <PageHeaderHeading>{t(i18nKeys.beeoimpact.pesticidesTable.pesticides)}</PageHeaderHeading>
          </PageHeader>
        </div>

        <div className="mb-4">
          <CallToACtion
            href={`mailto:info@beeodiversity.com?Subject=${encodeURIComponent(t(i18nKeys.beeoimpact.site.cta.subject, { siteName: site.properties?.name }))}`}
          />
        </div>
      </div>

      <div className="w-full pb-8">
        <div>
          {activeMatters.length !== 0 && (
            <div className="h-[30rem] w-full border">
              <PesticidesTableMap
                landplots={landplots}
                area={site.geometry}
                selectedPoint={center}
                selectedPesticide={selectedPesticide}
              />
            </div>
          )}
        </div>

        <div className="flex flex-col">
          <H3 className="mb-4">{t(i18nKeys.beeoimpact.pesticidesTable.activeMatters.table.title)}</H3>
          <Separator />
        </div>
        <Card className="mt-8 w-full min-w-0">
          <CardContent className="my-8">
            <PesticidesTable
              activeMatters={activeMatters}
              setSelectedPesticide={setSelectedPesticide}
              selectedPesticide={selectedPesticide}
            />
          </CardContent>
        </Card>

        <div>
          <div className="my-8">
            <H3 className="mb-4">{t(i18nKeys.beeoimpact.pesticidesTable.cultures.table.title)}</H3>
            <Separator />
          </div>

          <Card className="mt-8 w-full min-w-0">
            <CardContent className="my-8">
              <div className="flex w-full flex-row justify-center">
                <div className="w-1/2">
                  <CulturesTable cultures={cultures} selectedPlot={selectedPlot} />
                </div>
                <div className="ml-4 w-1/2">
                  <CulturesPieChart cultures={cultures} />
                </div>
              </div>
            </CardContent>
          </Card>
        </div>
      </div>
    </div>
  )
}

export default PesticidesDetailsPage

import { SensitiveAreaOut, Site } from '@/client/backend/models'
import { i18nKeys } from '@/locales/keys'
import { useTranslation } from 'react-i18next'

import { SENSITIVE_AREA_COLORS } from '@/lib/constants/sensitive-area-colors'
import { Badge } from '@/components/ui/badge'

import { Table, TableCell, TableHeaderCell, TableRow } from './table.print'

interface ClcTableProps {
  designatedAreas: SensitiveAreaOut[]
  protectedAreas: SensitiveAreaOut[]
  site: Site
}

type MixedSensitiveArea = SensitiveAreaOut & { type: 'N2000' | 'CDDA'; radius: number | undefined }

const SensitiveAreasTablePrint = ({ designatedAreas, protectedAreas, site }: ClcTableProps) => {
  const { t } = useTranslation()

  const protectedAndDesignatedAreasType =
    protectedAreas !== null && designatedAreas !== null
      ? ([
          ...protectedAreas.map((p) => ({
            ...p,
            type: 'N2000',
            radius: site.properties?.biodiversity_radius,
          })),
          ...designatedAreas.map((d) => ({
            ...d,
            type: 'CDDA',
            radius: site.properties?.biodiversity_radius,
          })),
        ] as MixedSensitiveArea[])
      : []

  return (
    <Table
      columns={6}
      header={
        <>
          <TableHeaderCell>{t(i18nKeys.beeoimpact.biodiversityTable.biodiversityReserves.table.type)}</TableHeaderCell>
          <TableHeaderCell>
            {t(i18nKeys.beeoimpact.biodiversityTable.biodiversityReserves.table.radius)}
          </TableHeaderCell>
          <TableHeaderCell>{t(i18nKeys.beeoimpact.biodiversityTable.biodiversityReserves.table.name)}</TableHeaderCell>
          <TableHeaderCell>
            {t(i18nKeys.beeoimpact.biodiversityTable.biodiversityReserves.table.position)}
          </TableHeaderCell>
          <TableHeaderCell>
            {t(i18nKeys.beeoimpact.biodiversityTable.biodiversityReserves.table.surface)}
          </TableHeaderCell>
          <TableHeaderCell>
            {t(i18nKeys.beeoimpact.biodiversityTable.biodiversityReserves.table.distance)}
          </TableHeaderCell>
        </>
      }
    >
      {protectedAndDesignatedAreasType.map(({ type, radius, name, location, surface_ha, distance_m }, i) => {
        return (
          <TableRow key={i}>
            <TableCell>
              <Badge
                className={
                  type === 'CDDA'
                    ? 'flex h-6 w-12 justify-center bg-indigo-600 text-center text-xs text-white'
                    : 'flex h-6 w-12 justify-center bg-orange-400 text-center text-xs text-white'
                }
              >
                {type}
              </Badge>
            </TableCell>
            <TableCell>
              <span>{radius}</span>
            </TableCell>
            <TableCell>
              <span>{name}</span>
            </TableCell>
            <TableCell>
              <Badge className={`w-max ${SENSITIVE_AREA_COLORS[location]}`}>
                {t(i18nKeys.beeoimpact.biodiversityTable.location[location])}
              </Badge>
            </TableCell>
            <TableCell>
              <span>{surface_ha?.toFixed(2)}</span>
            </TableCell>
            <TableCell>
              <span>{distance_m ? distance_m.toFixed(2) : ''}</span>
            </TableCell>
          </TableRow>
        )
      })}
    </Table>
  )
}

export default SensitiveAreasTablePrint

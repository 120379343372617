import { useMemo } from 'react'
import { CLCPlot } from '@/client/backend/models'
import { i18nKeys } from '@/locales/keys'
import { ColumnDef } from '@tanstack/react-table'
import { useTranslation } from 'react-i18next'

import { getClcTableData, ZoneNamesColor } from '@/lib/clc'
import { DataTable } from '@/components/data-table/data-table'
import { DataTableColumnHeader } from '@/components/data-table/data-table-column-header'

interface ClcTableProps {
  clc?: CLCPlot[]
}

const ClcTable = ({ clc = [] }: ClcTableProps) => {
  const { t } = useTranslation()

  const data = useMemo(() => getClcTableData(clc), [clc])

  const columns: ColumnDef<ZoneNamesColor>[] = [
    {
      accessorKey: 'name',
      header: ({ column }) => (
        <DataTableColumnHeader
          column={column}
          children={t(i18nKeys.beeoimpact.biodiversityTable.biodiversityReserves.table.name)}
        />
      ),
      cell: ({ row }) => {
        return <span className="max-w-[500px] truncate font-medium">{row.original.name}</span>
      },
    },
    {
      accessorKey: 'zone',
      header: ({ column }) => (
        <DataTableColumnHeader
          column={column}
          children={t(i18nKeys.beeoimpact.biodiversityTable.biodiversityReserves.table.type)}
        />
      ),
      cell: ({ row }) => {
        return <span className="max-w-[500px] truncate font-medium">{t(row.original.zoneKey)}</span>
      },
    },
    {
      accessorKey: 'area',
      header: ({ column }) => (
        <DataTableColumnHeader
          column={column}
          children={t(i18nKeys.beeoimpact.biodiversityTable.biodiversityReserves.table.surface)}
        />
      ),
      cell: ({ row }) => {
        return <span className="max-w-[500px] truncate font-medium">{row.original.surface.toFixed(2)}</span>
      },
    },
    {
      accessorKey: 'percentage',
      header: ({ column }) => (
        <DataTableColumnHeader
          column={column}
          children={t(i18nKeys.beeoimpact.biodiversityTable.biodiversityReserves.table.surfacePercentage)}
        />
      ),
      cell: ({ row }) => {
        return <span className="max-w-[500px] truncate font-medium">{row.original.percentage.toFixed(2)}</span>
      },
    },
    {
      accessorKey: 'color',
      header: ({ column }) => (
        <DataTableColumnHeader
          column={column}
          children={t(i18nKeys.beeoimpact.biodiversityTable.biodiversityReserves.table.color)}
        />
      ),
      cell: ({ row }) => {
        return <div className={`size-4`} style={{ backgroundColor: row.original.color }}></div>
      },
    },
  ]
  return <DataTable columns={columns} data={data} filterIdColumn="name" />
}

export default ClcTable

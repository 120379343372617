import { Site, SpeciesSample } from '@/client/backend/models'
import { i18nKeys } from '@/locales/keys'
import { useTranslation } from 'react-i18next'

import { Icons } from '@/components/icons'

import { Table, TableCell, TableHeaderCell, TableRow } from './table.print'

interface SpeciesGbifTableProps {
  gbif: SpeciesSample[]
  site: Site
}

const SpeciesGbifTablePrint = ({ gbif }: SpeciesGbifTableProps) => {
  const { t } = useTranslation()

  const uniqueSpecies = Object.values(
    gbif.reduce(
      (acc, current) => {
        const { vernacular_name } = current

        if (vernacular_name) {
          acc[vernacular_name] = current // Direct mutation
        }

        return acc
      },
      {} as Record<string, SpeciesSample>
    )
  )

  return (
    <Table
      columns={5}
      header={
        <>
          <TableHeaderCell>{t(i18nKeys.beeoimpact.biodiversityTable.biodiversityReserves.table.name)}</TableHeaderCell>
          <TableHeaderCell>
            {t(i18nKeys.beeoimpact.biodiversityTable.biodiversityReserves.table.category)}
          </TableHeaderCell>
          <TableHeaderCell className="w-32 text-center">
            {t(i18nKeys.beeoimpact.biodiversityTable.biodiversityReserves.table.priority_species)}
          </TableHeaderCell>
          <TableHeaderCell className="w-32 text-center">
            {t(i18nKeys.beeoimpact.biodiversityTable.biodiversityReserves.table.is_invasive)}
          </TableHeaderCell>
        </>
      }
    >
      {uniqueSpecies?.map(
        ({ canonical_name, vernacular_name, iucn_status_eu, taxon_kingdom, is_invasive_alien_species }, i) => {
          return (
            <TableRow key={i}>
              <TableCell>
                <div className="flex flex-col">
                  <span className="font-medium">{canonical_name}</span>
                  <span className="text-xs text-muted-foreground">{vernacular_name}</span>
                </div>
              </TableCell>
              <TableCell>
                <span className="font-medium">{taxon_kingdom}</span>
              </TableCell>
              <TableCell>
                <span className=" flex justify-center">
                  {iucn_status_eu === 'CR' || iucn_status_eu === 'EN' ? <Icons.Check /> : null}
                </span>
              </TableCell>
              <TableCell>
                <span className="flex justify-center">{is_invasive_alien_species ? <Icons.Check /> : null}</span>
              </TableCell>
            </TableRow>
          )
        }
      )}
    </Table>
  )
}

export default SpeciesGbifTablePrint

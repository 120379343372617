import { Site } from '@/client/backend/models/site'
import { GeoJSON, LatLng, latLngBounds, LatLngBounds, LatLngExpression, polygon } from 'leaflet'

export const getSiteCenter = (site: Site): LatLng => {
  const coordinates = site.geometry?.coordinates?.[0]

  const bounds = getBounds(coordinates)
  return bounds.getCenter()
}

export const getBounds = (coordinates?: number[][]): LatLngBounds => {
  if (!coordinates || coordinates.length === 0) {
    return latLngBounds([0, 0], [0, 0])
  }

  const latLngCoordinates = convertToLatLng(coordinates)
  const p = polygon(latLngCoordinates)
  return p.getBounds()
}

/**
 * Converts GeoJSON coordinates to Leaflet-compatible LatLngExpressions.
 *
 * @param coordinates - The GeoJSON coordinates to transform.
 * @returns An array of LatLngExpression objects.
 */
const convertToLatLng = (coordinates: number[][] | number[][][] | undefined): LatLngExpression[] => {
  if (!coordinates || coordinates.length === 0) {
    return [{ lat: 0, lng: 0 }]
  }
  return GeoJSON.coordsToLatLngs(coordinates)
}

export const DEFAULT_RADIUS = 1500

export const getPoint = (coordinates: number[] | undefined): LatLngExpression => {
  if (!coordinates || coordinates.length === 0) {
    return { lat: 0, lng: 0 }
  }
  const lat = coordinates[1]
  const lng = coordinates[0]

  return { lat, lng }
}

export const DEFAULT_CENTER = { lat: 45.7831, lng: 3.0824 }

/**
 * Calculates the bounding box (LatLngBounds) from a given center point and radius.
 *
 * @param center - The central point (LatLng) from which the bounds are calculated.
 * @param radiusInMeters - The radius in meters around the center point to calculate the bounds.
 * @param bufferRatio - An optional buffer ratio to pad the bounds. A ratio of 0.1 extends the bounds by 10% in each direction. (default: 0.1)
 * @returns The calculated bounding box (LatLngBounds) with the specified radius and buffer.
 */
export const getBoundsFromCenterAndRadius = (
  center: LatLng,
  radiusInMeters: number,
  bufferRatio: number = 0.1
): LatLngBounds => {
  return center.toBounds(radiusInMeters * 2).pad(bufferRatio)
}

export type GeoJsonArea = {
  coordinates?: number[][][][] | number[][][]
  type?: 'Polygon' | 'MultiPolygon'
}

/**
 * Checks if the given coordinates match the structure of a GeoJSON Polygon.
 *
 * @param coordinates - The coordinates to check.
 * @returns True if the coordinates are valid for a Polygon.
 */
const isPolygonCoordinates = (coordinates: number[][][] | number[][][][]): coordinates is number[][][] =>
  Array.isArray(coordinates) &&
  Array.isArray(coordinates[0]) &&
  Array.isArray(coordinates[0][0]) &&
  coordinates[0][0].length === 2 &&
  typeof coordinates[0][0][0] === 'number'

/**
 * Checks if the given coordinates match the structure of a GeoJSON MultiPolygon.
 *
 * @param coordinates - The coordinates to check.
 * @returns True if the coordinates are valid for a MultiPolygon.
 */
const isMultiPolygonCoordinates = (coordinates: number[][][] | number[][][][]): coordinates is number[][][][] =>
  Array.isArray(coordinates) &&
  Array.isArray(coordinates[0]) &&
  Array.isArray(coordinates[0][0]) &&
  Array.isArray(coordinates[0][0][0]) &&
  coordinates[0][0][0].length === 2 &&
  typeof coordinates[0][0][0][0] === 'number'

/**
 * Converts a GeoJSON geometry object to a Leaflet-compatible geometry object.
 *
 * @param geometry - Object containing the coordinates and type of the GeoJSON geometry object.
 * @param geometry.coordinates - The coordinates of the GeoJSON geometry object.
 * @param geometry.type - The type of the GeoJSON geometry object ('Polygon' or 'MultiPolygon').
 * @returns The converted Leaflet-compatible geometry object, or null if the input is invalid.
 */
export const convertGeoJsonToGeometry = ({
  coordinates,
  type,
}: GeoJsonArea): LatLngExpression[] | LatLngExpression[][] | null => {
  if (!coordinates || !type) {
    return null
  }

  switch (type) {
    case 'Polygon': {
      if (isPolygonCoordinates(coordinates)) {
        return convertToLatLng(coordinates[0])
      }
      break
    }
    case 'MultiPolygon': {
      if (isMultiPolygonCoordinates(coordinates)) {
        return coordinates.flat().map((polygon) => convertToLatLng(polygon))
      }
      break
    }
    default:
      return null // Unsupported geometry type
  }

  return null // Invalid coordinates
}

import { CLCPlot } from '@/client/backend/models'
import { i18nKeys } from '@/locales/keys'
import { LatLng } from 'leaflet'
import { useTranslation } from 'react-i18next'
import { Circle, LayerGroup, LayersControl, MapContainer, ScaleControl, TileLayer } from 'react-leaflet'

import { getZoneWithCodes, getZoneWithPercentage } from '@/lib/clc'
import { ExternalLinks } from '@/lib/constants/external-links'
import { DEFAULT_RADIUS } from '@/lib/geo'
import SiteLocationMap from '@/components/beeoimpact/impacts/site-location-map'
import { Icons } from '@/components/icons'

interface HighLevelMapReportProps extends React.HTMLAttributes<HTMLDivElement> {
  siteId: string
  center: LatLng
  clc: CLCPlot[]
  onMapReady?: () => void
}

const HighLevelMapReport = ({ siteId, center, clc, onMapReady, className, ...props }: HighLevelMapReportProps) => {
  const { t } = useTranslation()

  const iconMapping = {
    1: <Icons.Building2 />,
    2: <Icons.Sprout />,
    3: <Icons.Leaf className="size-5" />,
    4: <Icons.Waves />,
    5: <Icons.Droplet />,
  }

  const zoneWithCodes = getZoneWithCodes(clc ?? [], siteId)

  const zoneWithPercentage = getZoneWithPercentage(zoneWithCodes, iconMapping)

  return (
    <div className={className} {...props}>
      <MapContainer
        className="z-0 size-96"
        center={center}
        zoom={13.5}
        zoomSnap={0.1}
        scrollWheelZoom={false}
        whenReady={onMapReady}
      >
        <Circle center={center} color={'cadetblue'} fillColor="cadetblue" radius={DEFAULT_RADIUS} />
        <TileLayer
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          attribution={`&copy; <a href="${ExternalLinks.Clc}">${t(i18nKeys.beeoimpact.site.attribution.clc)}</a>`}
        />
        <LayersControl position="topright">
          <LayersControl.Overlay checked name={t(i18nKeys.beeoimpact.site.layer.clc)}>
            <LayerGroup>
              {clc?.map((c) => {
                return <SiteLocationMap clc={c} key={c.id} />
              })}
            </LayerGroup>
          </LayersControl.Overlay>
          <ScaleControl position="topleft" />
        </LayersControl>
      </MapContainer>

      <div>
        <div className="flex flex-col justify-evenly space-y-4">
          {zoneWithPercentage.map(({ key, icon, percentage, zone }) => {
            return (
              <div className="flex flex-row space-x-4" key={key}>
                {icon}
                <div>
                  <p>{percentage.toFixed(2)} %</p>
                  <p className="text-muted-foreground">{zone}</p>
                </div>
              </div>
            )
          })}
        </div>
      </div>
    </div>
  )
}

export default HighLevelMapReport

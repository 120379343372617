import { useState } from 'react'
import { usersResetPasswordConfirmCreate } from '@/client/backend/api/users/users'
import { i18nKeys } from '@/locales/keys'
import Page404 from '@/pages/errors/404'
import { zodResolver } from '@hookform/resolvers/zod'
import { isAxiosError } from 'axios'
import { Loader2 } from 'lucide-react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'

import { goToLoginPage } from '@/lib/utils'
import { ActivateUserFormValues, ActivateUserSchema } from '@/lib/validation/activate-user-schema'
import { Button } from '@/components/ui/button'
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from '@/components/ui/form'
import { Input } from '@/components/ui/input'
import { toast } from '@/components/ui/use-toast'

const ActivateUserPage = () => {
  const { id, token } = useParams()
  const { t } = useTranslation()
  const [isLoading, setIsLoading] = useState(false)

  const form = useForm<ActivateUserFormValues>({
    resolver: zodResolver(ActivateUserSchema),
    defaultValues: {
      newPassword: '',
      confirmNewPassword: '',
    },
    shouldUnregister: false,
    mode: 'onChange',
  })

  if (!id || !token) {
    return <Page404 />
  }

  const onSubmit = async (data: ActivateUserFormValues) => {
    setIsLoading(true)
    try {
      await usersResetPasswordConfirmCreate({ uid: id, token: token, new_password: data.newPassword })

      toast({
        title: t(i18nKeys.global.auth.activateuser.success),
      })

      goToLoginPage()
    } catch (error) {
      if (isAxiosError(error) && error.response?.data) {
        if (error.response.data.new_password) {
          toast({
            title: t(i18nKeys.global.auth.activateuser.error),
            description: error.response.data.new_password[0],
            variant: 'destructive',
          })
        }
        if (error.response.data.token) {
          toast({
            title: t(i18nKeys.global.auth.activateuser.error),
            description: error.response.data.token[0],
            variant: 'destructive',
          })
          goToLoginPage()
        }
      } else {
        toast({
          title: t(i18nKeys.global.auth.activateuser.error),
          description: 'An error occurred. Please try again later.',
          variant: 'destructive',
        })
      }
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-8">
        <FormField
          control={form.control}
          name="newPassword"
          render={({ field }) => (
            <FormItem>
              <FormLabel> {t(i18nKeys.global.auth.activateuser.create_password)}</FormLabel>
              <FormControl>
                <Input type="password" {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="confirmNewPassword"
          render={({ field }) => (
            <FormItem>
              <FormLabel> {t(i18nKeys.global.auth.activateuser.password_confirmation)}</FormLabel>
              <FormControl>
                <Input type="password" {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />

        <Button type="submit" className="w-full" disabled={isLoading}>
          {isLoading && <Loader2 className="mr-2 size-4 animate-spin" />}
          {t(i18nKeys.global.auth.activateuser.submit)}
        </Button>
      </form>
    </Form>
  )
}

export default ActivateUserPage

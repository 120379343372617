import { useEffect, useMemo, useState } from 'react'
import { sitesList } from '@/client/backend/api/sites/sites'
import {
  LocationSensitiveAreaEnum,
  PaginatedSiteList,
  Site,
  SitePropertiesBuildingType,
  SitesListParams,
} from '@/client/backend/models'
import { i18nKeys } from '@/locales/keys'
import { ColumnFiltersState, SortingState, type ColumnDef } from '@tanstack/react-table'
import { useTranslation } from 'react-i18next'
import { Link, useSearchParams } from 'react-router-dom'

import { PaginationData } from '@/types/pagination'
import { SENSITIVE_AREA_COLORS } from '@/lib/constants/sensitive-area-colors'
import { hasGroupPermission } from '@/lib/permission'
import { Routes } from '@/lib/routes/routes'
import { getPath } from '@/lib/routes/utils'
import { useAuth } from '@/hooks/useAuth'
import { Badge } from '@/components/ui/badge'
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '@/components/ui/tooltip'
import { DataTableSSR } from '@/components/data-table-ssr/data-table-ssr'
import { DataTableColumnHeader } from '@/components/data-table/data-table-column-header'
import SettingsSitesTableAction from '@/components/settings-sites-table-action'

type SitesTableProps = {
  data?: PaginatedSiteList
  accountId?: number
}

const SitesTable = ({ data, accountId }: SitesTableProps) => {
  const { t } = useTranslation()
  const [searchParams] = useSearchParams()
  const { user } = useAuth()

  const [sites, setSites] = useState<Site[]>(data?.results?.features ?? [])
  const [sorting, setSorting] = useState<SortingState>([])
  const [filter, setFilter] = useState<ColumnFiltersState>([])

  const [pagination, setPagination] = useState<PaginationData>({
    count: data?.count ?? 0,
    current_page: (data?.current_page ?? 0) - 1,
    next_page: data?.next_page ?? null,
    previous_page: data?.previous_page ?? null,
    last_page: data?.last_page ?? 10,
    page_size: data?.page_size ?? 10,
  })

  useEffect(() => {
    const search = searchParams.get('search')
    if (search) {
      setFilter([{ id: 'name', value: search }])
    }
    const ordering = searchParams.get('ordering')
    if (ordering) {
      const splitOrdering = ordering.split(',').map((o) => {
        const desc = o.startsWith('-')
        return { id: o.replace('-', ''), desc: desc }
      })

      setSorting(splitOrdering)
    }
  }, [])

  useEffect(() => {
    const fetchSites = async (sorting: SortingState, filter: ColumnFiltersState, pagination: PaginationData) => {
      let params: SitesListParams = {
        page_size: pagination.page_size ?? 10,
        page: Number(pagination.current_page) + 1,
      }
      if (sorting.length > 0) {
        const ordering = sorting.map((s) => `${s.desc ? '-' : ''}${s.id}`).join(',')
        params = { ...params, ordering: ordering, page: 1 }
      }
      if (filter.length > 0) {
        const filterName = filter.find((f) => f.id === 'name')
        params = { ...params, search: filterName?.value as string, page: 1 }
      }
      if (pagination.current_page !== (data?.current_page ?? 0) - 1) {
        params = { ...params, page: Number(pagination.current_page) + 1, page_size: pagination.page_size ?? 10 }
      }

      if (sorting || filter || pagination) {
        const paginatedData = await sitesList(params)
        setSites(paginatedData.results?.features ?? [])
      }
    }

    fetchSites(sorting, filter, pagination)
  }, [sorting, filter, pagination])

  const getColumns = (): ColumnDef<Site>[] => {
    return [
      {
        accessorKey: 'properties.name',
        id: 'name',
        header: ({ column }) => {
          return (
            <DataTableColumnHeader
              column={column}
              children={t(i18nKeys.beeoimpact.sites.table.header.name)}
              className="ml-3"
            />
          )
        },
        cell: ({ row }) => {
          return (
            <div className="flex space-x-2">
              <Link
                className="max-w-[120px] truncate"
                to={getPath(Routes.BEEOIMPACT_SITE, {
                  params: {
                    siteId: row.original.id ?? '',
                  },
                })}
              >
                <span className="font-medium hover:underline">{row.getValue('name')}</span>
              </Link>
            </div>
          )
        },
      },
      {
        accessorKey: 'properties.building_type',
        id: 'building_type',
        header: ({ column }) => {
          return (
            <DataTableColumnHeader
              column={column}
              children={t(i18nKeys.beeoimpact.sites.table.header.type)}
              className="ml-3"
            />
          )
        },
        cell: ({ row }) => {
          const building_type = row.original.properties?.building_type ?? SitePropertiesBuildingType.OFFICE
          const colors = {
            [SitePropertiesBuildingType.OFFICE]: 'bg-red-300',
            [SitePropertiesBuildingType.INDUSTRIAL]: 'bg-orange-300',
          }
          return (
            <div className="flex space-x-2">
              {row.original.properties?.stats_biodiversity?.closest_reserve !== null && (
                <span className="w-[100px] truncate font-medium">
                  <Badge className={colors[building_type]}>
                    {t(i18nKeys.beeoimpact.sites.table.buildingType[building_type])}
                  </Badge>
                </span>
              )}
            </div>
          )
        },
      },
      {
        accessorKey: 'properties.biodiversity_radius',
        id: 'biodiversity_radius',
        header: ({ column }) => (
          <DataTableColumnHeader column={column} children={t(i18nKeys.beeoimpact.sites.table.header.analyzedBuffer)} />
        ),
        cell: ({ row }) => {
          return (
            <div className="flex justify-end space-x-2">
              {parseFloat(row.getValue('biodiversity_radius')) > 0 &&
                row.original.properties?.stats_biodiversity?.closest_reserve !== undefined && (
                  <span className="w-[150px] truncate font-medium">
                    {parseFloat(row.getValue('biodiversity_radius')).toFixed(2)}
                  </span>
                )}
            </div>
          )
        },
      },
      {
        accessorKey: 'properties.stats_biodiveristy.location_sensitive_area',
        id: 'stats_biodiversity__location_sensitive_area',
        header: ({ column }) => {
          return <DataTableColumnHeader column={column} children={t(i18nKeys.beeoimpact.sites.table.header.location)} />
        },
        cell: ({ row }) => {
          const location: LocationSensitiveAreaEnum =
            row.original.properties?.stats_biodiversity?.location_sensitive_area ?? LocationSensitiveAreaEnum.OUT
          return (
            <div className="flex space-x-2">
              <span className="w-[100px] truncate font-medium ">
                <Badge className={SENSITIVE_AREA_COLORS[location]}>
                  {t(i18nKeys.beeoimpact.sites.table.location[location])}
                </Badge>
              </span>
            </div>
          )
        },
      },
      {
        accessorFn: (row) => {
          const distance = row.properties?.stats_biodiversity?.closest_reserve?.distance_m
          return distance
        },
        id: 'stats_biodiversity__closest_reserve__distance_m',
        header: ({ column }) => {
          return <DataTableColumnHeader column={column} children={t(i18nKeys.beeoimpact.sites.table.header.distance)} />
        },
        cell: (row) => {
          const distance = row.getValue() as number

          return <span className="w-[50px] truncate font-medium">{distance > 0 ? distance.toFixed(2) : null}</span>
        },
      },
      {
        accessorKey: 'properties.stats_biodiveristy.total_surface_sensitive_area_sq_m',
        id: 'stats_biodiversity__total_surface_sensitive_area_sq_m',
        header: ({ column }) => {
          return (
            <DataTableColumnHeader column={column} children={t(i18nKeys.beeoimpact.sites.table.header.totalSurface)} />
          )
        },
        cell: ({ row }) => {
          const surface: number = row.original.properties?.stats_biodiversity?.total_surface_sensitive_area_sq_m ?? 0
          const surfaceHa = surface / 10000
          return <span className="w-[50px] truncate font-medium">{surfaceHa.toFixed(2)}</span>
        },
      },
      {
        accessorKey: 'properties.stats_biodiversity.protected_status',
        id: 'stats_biodiversity__protected_status',
        header: ({ column }) => {
          return (
            <DataTableColumnHeader
              column={column}
              children={t(i18nKeys.beeoimpact.sites.table.header.typeProtectedArea)}
            />
          )
        },
        cell: ({ row }) => {
          const protected_status = row.original.properties?.stats_biodiversity?.protected_status
          const names = row.original.properties?.stats_biodiversity?.sensitive_area?.names ?? []

          const colors = {
            ['N2K']: 'bg-green-300',
            ['CDDA']: 'bg-blue-300',
            ['BOTH']: 'bg-gray-300',
          }

          return (
            <div className="flex  space-x-2 ">
              <TooltipProvider>
                <Tooltip>
                  <TooltipTrigger asChild>
                    <span className="max-w-[500px] truncate font-medium">
                      {protected_status && protected_status !== 'NONE' && (
                        <Badge className={colors[protected_status]}>
                          {t(i18nKeys.beeoimpact.sites.table.type[protected_status])}
                        </Badge>
                      )}
                    </span>
                  </TooltipTrigger>
                  {names && names.length > 0 && (
                    <TooltipContent className="w-80">
                      {names.map((name, index) => {
                        return (
                          <p key={index}>
                            {name}
                            {index !== names.length - 1 && ','}
                          </p>
                        )
                      })}
                    </TooltipContent>
                  )}
                </Tooltip>
              </TooltipProvider>
            </div>
          )
        },
      },
      {
        accessorFn: (row) => {
          const nbTotalSpecies = row.properties?.stats_biodiversity?.nb_total_threatened_species
          return nbTotalSpecies
        },
        id: 'stats_biodiversity__nb_total_threatened_species',
        header: ({ column }) => {
          return (
            <DataTableColumnHeader
              column={column}
              children={t(i18nKeys.beeoimpact.sites.table.header.nbTotalSpecies)}
            />
          )
        },
        cell: (row) => {
          const nbTotalSpecies = row.getValue() as number

          return (
            <div className="flex justify-center space-x-2">
              <span className="max-w-[70px] font-medium text-red-500">{nbTotalSpecies}</span>
            </div>
          )
        },
        enableSorting: true,
      },
    ]
  }
  const columns = useMemo<ColumnDef<Site, unknown>[]>(() => getColumns(), [])

  return (
    <DataTableSSR
      columns={columns}
      data={sites}
      filterIdColumn="name"
      action={
        hasGroupPermission(user) ? (
          <SettingsSitesTableAction sites={sites} setSites={setSites} accountId={accountId} />
        ) : undefined
      }
      sorting={sorting}
      setSorting={setSorting}
      paginationData={pagination}
      setPaginationData={setPagination}
      setFilterData={setFilter}
    />
  )
}

export default SitesTable

import {
  getSitesCddaListQueryKey,
  getSitesClcListQueryKey,
  getSitesHabitatsListQueryKey,
  getSitesLandplotsListQueryKey,
  getSitesN2000ListQueryKey,
  getSitesRetrieveQueryKey,
  getSitesSpeciesListQueryKey,
  sitesCddaList,
  sitesClcList,
  sitesHabitatsList,
  sitesLandplotsList,
  sitesN2000List,
  sitesRetrieve,
  sitesSpeciesList,
} from '@/client/backend/api/sites/sites'
import { getUsersMeRetrieveQueryKey, usersMeRetrieve } from '@/client/backend/api/users/users'
import { i18nKeys } from '@/locales/keys'
import { useQuery } from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'

import { FeatureType } from '@/types/feature-type'
import { getThreatenedSpeciesNumber, getZoneWithCodes, getZoneWithPercentage } from '@/lib/clc'
import { getSiteCenter } from '@/lib/geo'
import { hasAccesTo } from '@/lib/permission'
import { Routes } from '@/lib/routes/routes'
import { getPath } from '@/lib/routes/utils'
import { useAuth } from '@/hooks/useAuth'
import { Skeleton } from '@/components/ui/skeleton'
import GeneralSummary from '@/components/beeoimpact/general-summary/general-summary'
import GeneralAnalysisSummary from '@/components/beeoimpact/impacts/general-analysis-summary'
import ImpactsSummary from '@/components/beeoimpact/impacts/impacts-summary'
import RisksSummary from '@/components/beeoimpact/pesticides/risks-summary'
import BreadcrumbComponent from '@/components/breadcrumb-component'
import { Icons } from '@/components/icons'
import Loader from '@/components/loader'
import { PageHeader, PageHeaderHeading } from '@/components/page-header'

import ReportButton from '../report/report-button'

const SiteSummaryPage = () => {
  const { t } = useTranslation()

  const { siteId } = useParams<{ siteId: string }>()
  if (!siteId) throw new Error('siteId is required')

  const { data: userMe, isSuccess: isUserMeLoaded } = useQuery({
    queryKey: [getUsersMeRetrieveQueryKey()],
    queryFn: () => usersMeRetrieve(),
  })
  const { data: site, isLoading: isLoadingSite } = useQuery({
    queryKey: [getSitesRetrieveQueryKey(siteId)],
    queryFn: () => sitesRetrieve(siteId),
  })

  const { data: landplots, isLoading: isLoadingLandplots } = useQuery({
    queryKey: [getSitesLandplotsListQueryKey(siteId)],
    queryFn: () => sitesLandplotsList(siteId),
  })

  const { data: clc, isLoading: isLoadingClc } = useQuery({
    queryKey: [getSitesClcListQueryKey(siteId)],
    queryFn: () => sitesClcList(siteId),
    enabled: isUserMeLoaded && hasAccesTo(userMe.features, FeatureType.POTENTIAL),
  })

  const { data: gbif, isLoading: isLoadingGbif } = useQuery({
    queryKey: [getSitesSpeciesListQueryKey(siteId)],
    queryFn: () => sitesSpeciesList(siteId),
    enabled: isUserMeLoaded && hasAccesTo(userMe.features, FeatureType.POTENTIAL),
  })

  const { data: n2k, isLoading: isLoadingN2k } = useQuery({
    queryKey: [getSitesN2000ListQueryKey(siteId)],
    queryFn: () => sitesN2000List(siteId),
  })

  const { data: cdda, isLoading: isLoadingCdda } = useQuery({
    queryKey: [getSitesCddaListQueryKey(siteId)],
    queryFn: () => sitesCddaList(siteId),
  })

  const { data: habitats, isLoading: isLoadingHabitats } = useQuery({
    queryKey: [getSitesHabitatsListQueryKey(siteId)],
    queryFn: () => sitesHabitatsList(siteId, undefined),
  })

  const { user } = useAuth()
  if (!user) return <Loader />

  const center = site && getSiteCenter(site)

  const activeMatters = site?.properties?.stats_pesticides?.probable_pesticides.slice(0, 5) ?? []

  const iconMapping = {
    1: <Icons.Building2 />,
    2: <Icons.Sprout />,
    3: <Icons.Leaf className="size-5" />,
    4: <Icons.Waves />,
    5: <Icons.Droplet />,
  }

  const zoneWithCodes = getZoneWithCodes(clc ?? [], siteId)

  const zoneWithPercentage = getZoneWithPercentage(zoneWithCodes, iconMapping)

  const threatenedSpeciesNumber = getThreatenedSpeciesNumber(gbif)

  return (
    <div className="container">
      <div className="flex flex-row justify-between">
        <BreadcrumbComponent
          breadcrumbItems={[
            {
              label: t(i18nKeys.beeoimpact.dashboard.breadcrumb.beeoapps),
              href: '/',
            },
            {
              label: t(i18nKeys.beeoimpact.dashboard.breadcrumb.beeoimpact),
            },
            {
              label: t(i18nKeys.beeoimpact.common.breadcrumb.sites),
              href: getPath(Routes.BEEOIMPACT_SITES),
            },
          ]}
          currentPage={site?.properties?.name}
        />
      </div>

      <PageHeader className="flex max-w-full flex-row justify-between">
        {isLoadingSite ? (
          <Skeleton className="h-[34px] w-max min-w-48" />
        ) : (
          <>
            <PageHeaderHeading>{site?.properties?.name}</PageHeaderHeading>
            <ReportButton siteId={siteId} siteName={site?.properties?.name} className="self-end"></ReportButton>
          </>
        )}
      </PageHeader>

      {isLoadingSite || isLoadingClc || isLoadingGbif ? (
        <Skeleton className="h-[450px] w-full" />
      ) : (
        <GeneralSummary
          site={site}
          zoneWithPercentage={zoneWithPercentage}
          threatenedSpeciesNumber={threatenedSpeciesNumber}
          isLoadingSite={isLoadingSite}
          isLoadingClc={isLoadingClc}
          isLoadingGbif={isLoadingGbif}
        />
      )}

      {hasAccesTo(user.features, FeatureType.POTENTIAL) && (
        <GeneralAnalysisSummary
          isLoadingClc={isLoadingClc}
          isLoadingGbif={isLoadingGbif}
          clc={clc}
          center={center}
          zoneWithPercentage={zoneWithPercentage}
          site={site}
          gbif={gbif}
          threatenedSpeciesNumber={threatenedSpeciesNumber}
          habitats={habitats}
          isLoadingHabitats={isLoadingHabitats}
        />
      )}
      {hasAccesTo(user.features, FeatureType.BIODIVERSITY) && (
        <ImpactsSummary
          center={center}
          protectedAreas={n2k}
          designatedAreas={cdda}
          site={site}
          isLoadingN2k={isLoadingN2k}
          isLoadingCdda={isLoadingCdda}
          isLoadingSite={isLoadingSite}
        />
      )}
      {hasAccesTo(user.features, FeatureType.PESTICIDES) && (
        <RisksSummary
          area={site?.geometry}
          selectedPoint={center}
          siteId={site?.id}
          landplots={landplots}
          activeMatters={activeMatters}
          isLoadingSite={isLoadingSite}
          isLoadingLandplots={isLoadingLandplots}
        />
      )}
    </div>
  )
}

export default SiteSummaryPage

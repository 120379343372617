import { useMemo } from 'react'
import { CLCPlot } from '@/client/backend/models'
import { i18nKeys } from '@/locales/keys'
import { useTranslation } from 'react-i18next'

import { getClcTableData } from '@/lib/clc'

import { Table, TableCell, TableHeaderCell, TableRow } from './table.print'

interface ClcTableProps {
  clc: CLCPlot[]
}

const ClcTablePrint = ({ clc }: ClcTableProps) => {
  const { t } = useTranslation()

  const content = useMemo(() => getClcTableData(clc), [clc])

  return (
    <Table
      columns={5}
      header={
        <>
          <TableHeaderCell>{t(i18nKeys.beeoimpact.biodiversityTable.biodiversityReserves.table.name)}</TableHeaderCell>
          <TableHeaderCell>{t(i18nKeys.beeoimpact.biodiversityTable.biodiversityReserves.table.type)}</TableHeaderCell>
          <TableHeaderCell>
            {t(i18nKeys.beeoimpact.biodiversityTable.biodiversityReserves.table.surface)}
          </TableHeaderCell>
          <TableHeaderCell>
            {t(i18nKeys.beeoimpact.biodiversityTable.biodiversityReserves.table.surfacePercentage)}
          </TableHeaderCell>
          <TableHeaderCell className="w-16">
            {t(i18nKeys.beeoimpact.biodiversityTable.biodiversityReserves.table.color)}
          </TableHeaderCell>
        </>
      }
    >
      {content.map(({ name, zoneKey, surface, percentage, color }, i) => {
        return (
          <TableRow key={i}>
            <TableCell>
              <span>{name}</span>
            </TableCell>
            <TableCell>
              <span>{t(zoneKey)}</span>
            </TableCell>
            <TableCell>
              <span>{surface.toFixed(2)}</span>
            </TableCell>
            <TableCell>
              <span>{percentage.toFixed(2)}</span>
            </TableCell>
            <TableCell className="flex justify-center">
              <svg width="16" height="16">
                <rect width="16" height="16" fill={color} />
              </svg>
            </TableCell>
          </TableRow>
        )
      })}
    </Table>
  )
}

export default ClcTablePrint

import { useEffect, useState } from 'react'
import {
  getSitesCddaListQueryKey,
  getSitesClcListQueryKey,
  getSitesN2000ListQueryKey,
  getSitesRetrieveQueryKey,
  getSitesSpeciesListQueryKey,
  sitesCddaList,
  sitesClcList,
  sitesN2000List,
  sitesRetrieve,
  sitesSpeciesList,
} from '@/client/backend/api/sites/sites'
import { i18nKeys } from '@/locales/keys'
import { useQuery } from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'

import { AvailableCountry } from '@/types/available-country'
import { getSiteCenter } from '@/lib/geo'
import CallToACtion from '@/components/beeoimpact/sites/cta'
import Footer from '@/components/footer'
import Loader from '@/components/loader'
import { H1, H2, H3, H4, P } from '@/components/typography'

import ClcTablePrint from './components/clc-table.print'
import HighLevelMapReport from './components/highlevel-map-report.print'
import RiskMapReport from './components/risk-map-report.print'
import SensitiveAreasTablePrint from './components/sensitive-areas-table.print'
import SpeciesGbifTablePrint from './components/species-gbif-table.print'
import SpeciesReport from './components/species.print'

interface SiteReportProps extends React.HTMLAttributes<HTMLDivElement> {
  siteId: string
  onReady?: () => void
}

const SiteReport = ({ className, siteId, onReady, ...props }: SiteReportProps) => {
  const { t } = useTranslation()

  const { data: site } = useQuery({
    queryFn: () => sitesRetrieve(siteId),
    queryKey: [getSitesRetrieveQueryKey(siteId)],
  })

  const { data: gbif } = useQuery({
    queryFn: () => sitesSpeciesList(siteId),
    queryKey: [getSitesSpeciesListQueryKey(siteId)],
  })

  const { data: clc } = useQuery({
    queryFn: () => sitesClcList(siteId),
    queryKey: [getSitesClcListQueryKey(siteId)],
  })

  const { data: n2k } = useQuery({
    queryFn: () => sitesN2000List(siteId),
    queryKey: [getSitesN2000ListQueryKey(siteId)],
  })

  const { data: cdda } = useQuery({
    queryFn: () => sitesCddaList(siteId),
    queryKey: [getSitesCddaListQueryKey(siteId)],
  })

  const [isHighLevelMapReady, setHighLevelMapReady] = useState(false)
  const [isRiskMapReady, setRiskMapReady] = useState(false)

  useEffect(() => {
    if (site && gbif && clc && n2k && cdda && isHighLevelMapReady && isRiskMapReady && onReady) {
      onReady()
    }
  }, [site, gbif, clc, n2k, cdda, isHighLevelMapReady, isRiskMapReady, onReady])

  if (!site || !gbif || !clc || !n2k || !cdda) {
    return (
      <div>
        <Loader status={t(i18nKeys.beeoimpact.report.loading)} />
      </div>
    )
  }

  const center = getSiteCenter(site)

  return (
    <table className={className} {...props}>
      <tbody>
        <tr>
          <td>
            <section className="mb-4">
              <H1>{t(i18nKeys.beeoimpact.report.title, { siteName: site?.properties?.name })}</H1>
              {site?.properties?.country && Object.keys(AvailableCountry).includes(site.properties.country) && (
                <P className="!mt-2">{t(i18nKeys.global.common.countries[site.properties.country.toLowerCase()])}</P>
              )}
              {site?.properties?.center?.coordinates && (
                <P className="!mt-2">
                  {t(i18nKeys.beeoimpact.report.position, {
                    longitude: site.properties.center.coordinates[0].toFixed(4),
                    latitude: site.properties.center.coordinates[1].toFixed(4),
                  })}
                </P>
              )}
              <P className="!mt-2">
                {t(i18nKeys.beeoimpact.report.influence, { radius: site?.properties?.biodiversity_radius })}
              </P>
            </section>

            <section className="mb-4">
              <H2>{t(i18nKeys.beeoimpact.report.highLevelAssessment.title)}</H2>
              <P className="mb-2">{t(i18nKeys.beeoimpact.report.highLevelAssessment.description)}</P>

              <HighLevelMapReport
                center={center}
                clc={clc}
                siteId={siteId}
                onMapReady={() => setHighLevelMapReady(true)}
                className="flex flex-row items-start gap-4"
              />
            </section>

            <section className="mb-4">
              <ClcTablePrint clc={clc} />
            </section>

            <section className="mb-4">
              <H2>{t(i18nKeys.beeoimpact.report.riskAssessment.title)}</H2>
              <P>{t(i18nKeys.beeoimpact.report.riskAssessment.mainDescription)}</P>
              <P>{t(i18nKeys.beeoimpact.report.riskAssessment.areaOfInfluenceDescription)}</P>
              <P>{t(i18nKeys.beeoimpact.report.riskAssessment.biodiversityScoreDescription)}</P>
              <P className="mb-2">{t(i18nKeys.beeoimpact.report.riskAssessment.sensitiveAreasDescription)}</P>

              <RiskMapReport
                site={site}
                cdda={cdda}
                n2k={n2k}
                center={center}
                onMapReady={() => setRiskMapReady(true)}
                className="flex flex-row items-start gap-4"
              />
            </section>

            <section className="mb-4">
              <SensitiveAreasTablePrint protectedAreas={n2k} designatedAreas={cdda} site={site} />
            </section>

            <section className="mb-4">
              <H3>{t(i18nKeys.beeoimpact.report.riskAssessment.species.title)}</H3>
              <P>{t(i18nKeys.beeoimpact.report.riskAssessment.species.description)}</P>
              <P>{t(i18nKeys.beeoimpact.report.riskAssessment.species.dataSourcesDescription)}</P>
              <P>{t(i18nKeys.beeoimpact.report.riskAssessment.species.dataExhaustivenessNote)}</P>
              <H4>{t(i18nKeys.beeoimpact.report.riskAssessment.species.endangeredSpeciesTitle)}</H4>
              <P>{t(i18nKeys.beeoimpact.report.riskAssessment.species.gbifSpeciesDescription)}</P>
              <P>{t(i18nKeys.beeoimpact.report.riskAssessment.species.prioritySpeciesDefinition)}</P>

              <SpeciesGbifTablePrint gbif={gbif} site={site} />
            </section>

            <section className="mb-4">
              <H4>{t(i18nKeys.beeoimpact.report.riskAssessment.species.threatenedSpeciesTitle)}</H4>
              <P>{t(i18nKeys.beeoimpact.report.riskAssessment.species.threatenedSpeciesDescription)}</P>

              <SpeciesReport site={site} n2k={n2k} />
            </section>

            <section>
              <CallToACtion
                href={`mailto:info@beeodiversity.com?Subject=${encodeURIComponent(t(i18nKeys.beeoimpact.site.cta.subject, { siteName: site.properties?.name }))}`}
              />
            </section>
          </td>
        </tr>
      </tbody>
      <tfoot>
        <tr>
          <td>
            <Footer />
          </td>
        </tr>
      </tfoot>
    </table>
  )
}

export default SiteReport

import { useContext } from 'react'
import { PrintContext } from '@/context/print-context'
import { Loader } from 'lucide-react'
import { useParams } from 'react-router-dom'

import SiteReport from './report'

const SiteReportPreviewPage = () => {
  const { siteId } = useParams<{ siteId: string }>()

  if (!siteId) {
    throw new Error('siteId is required')
  }

  const printContext = useContext(PrintContext)

  if (!printContext) {
    throw new Error('PrintContext must be used within a PrintProvider')
  }

  const { displayPrintContent, setIsPrintableContentReady } = printContext

  displayPrintContent(<SiteReport siteId={siteId} onReady={() => setIsPrintableContentReady(true)} />, undefined, true)

  return <Loader />
}

export default SiteReportPreviewPage

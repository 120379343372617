import { i18nKeys } from '@/locales/keys'
import { t } from 'i18next'
import { FileUp } from 'lucide-react'

import { Button } from '@/components/ui/button'
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '@/components/ui/dialog'
import { ScrollArea } from '@/components/ui/scroll-area'
import ImportSites from '@/components/import-sites'

type ImportSiteProps = {
  accountId?: number
}
const ImportSite = ({ accountId }: ImportSiteProps) => {
  return (
    <Dialog>
      <DialogTrigger asChild>
        <Button size="sm" className="ml-2 h-8 lg:flex">
          <FileUp className="mr-2 size-4" />
          {t('global.common.buttons.importSites')}
        </Button>
      </DialogTrigger>
      <DialogContent className="h-[90%] min-w-[90%] sm:max-w-[425px]">
        <ScrollArea>
          <DialogHeader>
            <DialogTitle>{t(i18nKeys.global.common.buttons.importSites)}</DialogTitle>
            <DialogDescription>{t(i18nKeys.global.common.buttons.importSitesSubtitle)}</DialogDescription>
            <ImportSites accountId={accountId} />
          </DialogHeader>
        </ScrollArea>
      </DialogContent>
    </Dialog>
  )
}

export default ImportSite

import { useMemo } from 'react'
import { useAccountsStatsPesticidesRetrieve } from '@/client/backend/api/accounts/accounts'
import { getUsersMeRetrieveQueryKey, usersMeRetrieve } from '@/client/backend/api/users/users'
import { Site } from '@/client/backend/models'
import { i18nKeys } from '@/locales/keys'
import { ResponsiveBar } from '@nivo/bar'
import { useQuery } from '@tanstack/react-query'
import { AlertTriangle, FlaskConical, MapPin } from 'lucide-react'
import { useTranslation } from 'react-i18next'

import { FeatureType } from '@/types/feature-type'
import { hasAccesTo } from '@/lib/permission'
import { toTitleCase } from '@/lib/string'
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from '@/components/ui/card'
import { Skeleton } from '@/components/ui/skeleton'
import NoSitesMessage from '@/components/no-sites-message'

interface DashboardPesticidesProps {
  sites: Site[]
  isLoadingSites: boolean
}

const ChartCard = ({ title, description, data, keys, indexBy, colors }) => {
  const { t } = useTranslation()
  return (
    <Card className="flex min-h-[80px] w-full min-w-0 flex-col lg:w-1/2">
      <CardHeader>
        <CardTitle>{title}</CardTitle>
        <CardDescription>{description}</CardDescription>
      </CardHeader>
      <CardContent className="grid gap-1">
        <div style={{ height: '400px' }}>
          {data && data.length > 0 ? (
            <ResponsiveBar
              data={data}
              keys={keys}
              indexBy={indexBy}
              margin={{ top: 40, right: 100, bottom: 120, left: 60 }}
              padding={0.3}
              colorBy="indexValue"
              colors={colors}
              labelTextColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
              axisBottom={{
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 90,
              }}
            />
          ) : (
            <div className="flex h-full flex-col items-center justify-center text-center">
              <h3 className="text-xl ">{t(i18nKeys.global.common.data.noDataAvailable)}</h3>
              <p className="text-muted-foreground">{t(i18nKeys.global.common.data.noDataToDisplay)}</p>
            </div>
          )}
        </div>
      </CardContent>
    </Card>
  )
}

const DashboardPesticides = ({ sites, isLoadingSites }: DashboardPesticidesProps) => {
  const { t } = useTranslation()

  const { data: user } = useQuery({
    queryKey: [getUsersMeRetrieveQueryKey()],
    queryFn: () => usersMeRetrieve(undefined),
  })

  const { data: stats, isLoading: isLoadingStats } = useAccountsStatsPesticidesRetrieve(user?.account_id ?? 0, {
    query: { enabled: !!user && hasAccesTo(user?.features ?? [], FeatureType.PESTICIDES) },
  })

  const sortedPesticides = useMemo(() => {
    return (
      stats?.list_pesticides
        .sort((a, b) => b.occurrence - a.occurrence)
        .slice(0, 10)
        .map((pesticide) => ({
          ...pesticide,
          name: toTitleCase(pesticide.name),
        })) || []
    )
  }, [stats])

  const sortedCultures = useMemo(() => {
    return stats?.list_culture.sort((a, b) => b.hectare - a.hectare).slice(0, 10) || []
  }, [stats])

  return (
    <>
      {sites.length === 0 ? (
        <NoSitesMessage />
      ) : (
        <section>
          <div className="mb-4 grid gap-4 md:grid-cols-2 lg:grid-cols-4">
            {isLoadingSites ? (
              <Skeleton className="h-[154px]" />
            ) : (
              <CardFull
                title={t(i18nKeys.beeoimpact.dashboard.pesticides.sites.title)}
                description={t(i18nKeys.beeoimpact.dashboard.pesticides.sites.description, { count: sites.length })}
                value={sites.length}
                icon={<MapPin className="size-4 text-muted-foreground" />}
              />
            )}
            {isLoadingStats ? (
              <Skeleton className="h-[154px]" />
            ) : (
              <CardFull
                title={t(i18nKeys.beeoimpact.dashboard.pesticides.sitesAtRisk.title)}
                description={t(i18nKeys.beeoimpact.dashboard.pesticides.sitesAtRisk.description)}
                value={stats?.nb_sites_at_risk}
                icon={<AlertTriangle className="size-4 text-muted-foreground" />}
              />
            )}
            {isLoadingStats ? (
              <Skeleton className="h-[154px]" />
            ) : (
              <CardFull
                title={t(i18nKeys.beeoimpact.dashboard.pesticides.sitesWithPesticides.title)}
                description={t(i18nKeys.beeoimpact.dashboard.pesticides.sitesWithPesticides.description)}
                value={stats?.list_pesticides.length}
                icon={<FlaskConical className="size-4 text-muted-foreground" />}
              />
            )}
          </div>
          <div className="my-8 flex flex-col items-center justify-center space-y-8 lg:flex-row lg:space-x-4 lg:space-y-0">
            {isLoadingStats ? (
              <>
                <Skeleton className="h-[520px] w-full lg:w-1/2" />
                <Skeleton className="h-[520px] w-full lg:w-1/2" />
              </>
            ) : (
              <>
                <ChartCard
                  title={t(i18nKeys.beeoimpact.dashboard.pesticides.mostPesticidesFound.title)}
                  description={t(i18nKeys.beeoimpact.dashboard.pesticides.mostPesticidesFound.description, {
                    count: sites.length,
                  })}
                  data={sortedPesticides}
                  keys={['occurrence']}
                  indexBy="name"
                  colors={[
                    '#991b1b',
                    '#991b1b',
                    '#b91c1c',
                    '#b91c1c',
                    '#dc2626',
                    '#dc2626',
                    '#ef4444',
                    '#ef4444',
                    '#f87171',
                    '#f87171',
                  ]}
                />
                <ChartCard
                  title={t(i18nKeys.beeoimpact.dashboard.pesticides.mostCommonCultures.title)}
                  description={t(i18nKeys.beeoimpact.dashboard.pesticides.mostCommonCultures.description, {
                    count: sites.length,
                  })}
                  data={sortedCultures}
                  keys={['hectare']}
                  indexBy="name"
                  colors={[
                    '#93c5fd',
                    '#93c5fd',
                    '#60a5fa',
                    '#60a5fa',
                    '#3b82f6',
                    '#3b82f6',
                    '#2563eb',
                    '#2563eb',
                    '#1d4ed8',
                    '#1d4ed8',
                  ]}
                />
              </>
            )}
          </div>
        </section>
      )}
    </>
  )
}

interface CardFullProps {
  title: string
  description: string
  value?: number
  icon: React.ReactNode
}

const CardFull = ({ title, description, value, icon }: CardFullProps) => {
  return (
    <Card>
      <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
        <CardTitle className="text-sm font-medium">
          {title}
          <CardDescription>{description}</CardDescription>
        </CardTitle>
        <div className="text-sm">{icon}</div>
      </CardHeader>

      <CardContent>
        <div className="text-4xl font-bold">{value}</div>
      </CardContent>
    </Card>
  )
}

export default DashboardPesticides

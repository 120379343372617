import { SensitiveAreaOut, Site } from '@/client/backend/models'
import { i18nKeys } from '@/locales/keys'
import { useTranslation } from 'react-i18next'

import { SpeciesType } from '@/types/species-type'
import {
  filtercategoryCrEn,
  getCategories,
  getIucnSpeciesCount,
  getNotIucnSpeciesCount,
  getUniqueSpecies,
} from '@/lib/n2k'
import SpeciesCards from '@/components/beeoimpact/impacts/species-cards'
import CardAnalytics from '@/components/card-analytics'
import { Icons } from '@/components/icons'
import { SpeciesIcon } from '@/components/icons-components/active-matters-icons'
import { H3, H4, P } from '@/components/typography'

import PrioritySpeciesTablePrint from './priority-species-table.print'

interface SpeciesReportProps extends React.HTMLAttributes<HTMLDivElement> {
  site: Site
  n2k: SensitiveAreaOut[]
}

const SpeciesReport = ({ site, n2k, className, ...props }: SpeciesReportProps) => {
  const { t } = useTranslation()

  const uniqueSpecies = getUniqueSpecies(n2k)
  const categories = getCategories(uniqueSpecies)
  const filteredUniqueSpeciesCrEn = filtercategoryCrEn(uniqueSpecies)
  const iucnSpeciesSum = getIucnSpeciesCount(uniqueSpecies)
  const otherStatusSum = getNotIucnSpeciesCount(uniqueSpecies)

  return (
    <div className={className} {...props}>
      {filteredUniqueSpeciesCrEn.length === 0 && uniqueSpecies.length === 0 ? (
        <div className="container flex h-80 items-center justify-center rounded-md border border-dashed">
          <div className="mx-auto flex max-w-[420px] flex-col items-center justify-center text-center">
            <Icons.Squirrel size={42} />
            <h3 className="mt-4 text-lg font-semibold">
              {t(i18nKeys.beeoimpact.biodiversityTable.biodiversitySpecies.noSpecies)}
            </h3>
            <div className="mb-4 mt-2 text-sm text-muted-foreground">
              {t(i18nKeys.beeoimpact.biodiversityTable.biodiversitySpecies.noPrioritySpecies)}
            </div>
          </div>
        </div>
      ) : (
        <>
          <H4>{t(i18nKeys.beeoimpact.biodiversityTable.biodiversitySpecies.invasiveSpecies)}</H4>
          <div className="mb-4 grid grid-cols-2 gap-4">
            <CardAnalytics
              icon={
                <SpeciesIcon
                  value={site.properties?.stats_biodiversity?.invasive_alien_species_in_sensitive_areas?.animals ?? 0}
                  species={SpeciesType.Squirrel}
                />
              }
              value={site.properties?.stats_biodiversity?.invasive_alien_species_in_sensitive_areas?.animals ?? 0}
              label={t(i18nKeys.beeoimpact.biodiversityTable.biodiversitySpecies.animal)}
            />
            <CardAnalytics
              icon={
                <SpeciesIcon
                  value={site.properties?.stats_biodiversity?.invasive_alien_species_in_sensitive_areas?.plants ?? 0}
                  species={SpeciesType.Plant}
                />
              }
              value={site.properties?.stats_biodiversity?.invasive_alien_species_in_sensitive_areas?.plants ?? 0}
              label={t(i18nKeys.beeoimpact.biodiversityTable.biodiversitySpecies.plant)}
            />
          </div>
          <div className="mb-2 flex flex-col">
            <H3>{t(i18nKeys.beeoimpact.biodiversityTable.biodiversitySpecies.taxonsArea)} :</H3>
            <P>
              <span className="pl-1 font-semibold">{iucnSpeciesSum} </span>
              {t(i18nKeys.beeoimpact.biodiversityTable.biodiversitySpecies.taxonsArea2)}{' '}
              <span className="font-semibold"> {otherStatusSum} </span>{' '}
              {t(i18nKeys.beeoimpact.biodiversityTable.biodiversitySpecies.taxonsArea3)}
            </P>
          </div>
          <div>
            <SpeciesCards categories={categories} />
          </div>
        </>
      )}
      {filteredUniqueSpeciesCrEn.length !== 0 ? (
        <>
          <h3 className="mb-2 ">
            {' '}
            {t(i18nKeys.beeoimpact.biodiversityTable.biodiversitySpecies.table.prioritySpecies)}
          </h3>
          <PrioritySpeciesTablePrint data={filteredUniqueSpeciesCrEn} />
        </>
      ) : (
        <div className="container mt-4 flex h-80 items-center justify-center rounded-md border border-dashed">
          <div className="mx-auto flex max-w-[420px] flex-col items-center justify-center text-center">
            <Icons.Squirrel size={42} />
            <h3 className="mt-4 w-full text-lg font-semibold">
              {t(i18nKeys.beeoimpact.biodiversityTable.biodiversitySpecies.noSpecies2)}
            </h3>
            <div className="mb-4 mt-2 text-sm text-muted-foreground">
              {t(i18nKeys.beeoimpact.biodiversityTable.biodiversitySpecies.noPrioritySpecies)}
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default SpeciesReport

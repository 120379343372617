import { CLCPlot } from '@/client/backend/models'
import { Polygon, Tooltip } from 'react-leaflet'

import { convertGeoJsonToGeometry, GeoJsonArea } from '@/lib/geo'

interface SiteLocationMapProps {
  clc: CLCPlot
}
const SiteLocationMap = ({ clc }: SiteLocationMapProps) => {
  const newGeom = convertGeoJsonToGeometry(clc.intersection as GeoJsonArea)
  if (!newGeom) {
    return null
  }

  const fillColor = clc.type.color
  const options = {
    color: 'white',
    fillColor: fillColor ? fillColor : 'white',
    fillOpacity: 0.5,
    weight: 1,
    opacity: 2,
  }
  return (
    <Polygon pathOptions={options} positions={newGeom} className="outline-none" pane="markerPane">
      <Tooltip sticky>
        <ul>{clc.type.name_level3}</ul>
      </Tooltip>
    </Polygon>
  )
}

export default SiteLocationMap

import { FC, HTMLAttributes, useCallback, useContext } from 'react'
import { PrintContext } from '@/context/print-context'
import { i18nKeys } from '@/locales/keys'
import { useTranslation } from 'react-i18next'

import { Button } from '@/components/ui/button'
import { Icons } from '@/components/icons'

import SiteReport from './report'

interface ReportButtonProps extends HTMLAttributes<HTMLButtonElement> {
  siteId: string
  siteName?: string
}

const ReportButton: FC<ReportButtonProps> = ({ siteId, siteName, className, ...props }) => {
  const { t } = useTranslation()
  const printContext = useContext(PrintContext)

  if (!printContext) {
    throw new Error('PrintContext must be used within a PrintProvider')
  }

  const { displayPrintContent, setIsPrintableContentReady } = printContext

  const handlePrint = useCallback(() => {
    const date = new Date().toLocaleDateString()
    const filename = t(i18nKeys.beeoimpact.report.filename, { siteName, date })
    displayPrintContent(<SiteReport siteId={siteId} onReady={() => setIsPrintableContentReady(true)} />, filename)
  }, [displayPrintContent, setIsPrintableContentReady, siteId])

  return (
    <Button onClick={handlePrint} className={className} {...props}>
      <Icons.BeeIcon className="mr-2" />
      {t(i18nKeys.beeoimpact.report.cta)}
    </Button>
  )
}

export default ReportButton

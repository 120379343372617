import { SensitiveAreaOut } from '@/client/backend/models'

export interface SubSpecies {
  name: string
  group: string
  iucn_category: string
  id: number
  protectedAreaId: number
  vernacular_name: string
}

const keepUniqueSpeciesNames = (array: SubSpecies[]) => {
  return array.filter((v, i, a) => a.findIndex((t) => t.name === v.name) === i)
}

export const getUniqueSpecies = (protectedAreas: SensitiveAreaOut[]) => {
  const speciesCustomList = protectedAreas?.map((protectedArea) => {
    return [
      ...protectedArea.speciesfound.map((species) => {
        return {
          ...species,
          protectedAreaId: protectedArea.id,
        }
      }),
    ]
  })

  const speciesListFlat = speciesCustomList.flat(4)

  const keysToKeep = ['name', 'group', 'iucn_category', 'id', 'protectedAreaId', 'vernacular_name']
  const subSpeciesList = speciesListFlat.map((obj) =>
    keysToKeep.reduce((acc, key) => {
      return { ...acc, [key]: obj[key] }
    }, {})
  ) as SubSpecies[]

  return keepUniqueSpeciesNames(subSpeciesList).sort((a, b) => a.name.localeCompare(b.name))
}

export const getCategories = (uniqueSpecies: SubSpecies[]) => {
  const categories = {}

  uniqueSpecies.forEach((species) => {
    const animalGroup = species.group
    const category = species.iucn_category
    if (!categories[animalGroup]) {
      categories[animalGroup] = {
        CR: 0,
        EN: 0,
        VU: 0,
        NT: 0,
        LC: 0,
        Other: 0,
        TotalMainCategs: 0,
      }
    }
    if (category === 'CR' || category === 'EN' || category === 'VU' || category === 'NT' || category === 'LC') {
      categories[animalGroup][category]++
      categories[animalGroup]['TotalMainCategs']++
    } else {
      categories[animalGroup]['Other']++
      categories[animalGroup]['TotalMainCategs']++
    }
  })

  return categories
}

const IucnCategories = ['CR', 'NT', 'VU', 'EN', 'LC']

export const getIucnSpeciesCount = (species: SubSpecies[]) => {
  return species.map((c) => c.iucn_category).filter((c) => IucnCategories.includes(c)).length
}

export const getNotIucnSpeciesCount = (species: SubSpecies[]) => {
  return species.map((c) => c.iucn_category).filter((c) => !IucnCategories.includes(c)).length
}

export const filtercategoryCrEn = (species: SubSpecies[]) => {
  return species.filter((specie) => specie.iucn_category === 'CR' || specie.iucn_category === 'EN')
}

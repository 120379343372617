import { i18nKeys } from '@/locales/keys'
import { sitesSettingsLoader } from '@/pages/settings/sites.loader'
import { useTranslation } from 'react-i18next'
import { useLoaderData } from 'react-router'

import { Separator } from '@/components/ui/separator'
import SettingsSitesTable from '@/components/settings-sites-table'

const SitesSettings = () => {
  const { t } = useTranslation()
  const { sites } = useLoaderData() as Awaited<ReturnType<typeof sitesSettingsLoader>>

  return (
    <div className="space-y-6">
      <div>
        <h3 className="text-lg font-medium">{t(i18nKeys.global.settings.page.sites.title)}</h3>
        <p className="text-sm text-muted-foreground">{t(i18nKeys.global.settings.page.sites.description)}</p>
      </div>
      <Separator />
      <SettingsSitesTable data={sites.features ?? []} />
    </div>
  )
}

export default SitesSettings

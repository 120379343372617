import { Dispatch, SetStateAction, useState } from 'react'
import { Site } from '@/client/backend/models'
import { i18nKeys } from '@/locales/keys'
import { t } from 'i18next'

import { Button } from '@/components/ui/button'
import { Sheet, SheetContent, SheetDescription, SheetHeader, SheetTitle, SheetTrigger } from '@/components/ui/sheet'
import { Icons } from '@/components/icons'
import SiteForm from '@/components/site-form'

type AddBomSiteProps = {
  sites: Site[]
  setSites: Dispatch<SetStateAction<Site[]>>
  accountId?: number
}
const AddBomSite = ({ sites, setSites, accountId }: AddBomSiteProps) => {
  const [openBomSheet, setOpenBomSheet] = useState(false)
  return (
    <Sheet open={openBomSheet} onOpenChange={setOpenBomSheet}>
      <SheetTrigger asChild>
        <Button size="sm" className="ml-2 h-8 lg:flex">
          <Icons.Plus className="mr-2 size-4" />
          {t(i18nKeys.global.common.buttons.addBom)}
        </Button>
      </SheetTrigger>
      <SheetContent side="right" className="overflow-y-scroll">
        <SheetHeader>
          <SheetTitle> {t(i18nKeys.global.settings.page.sites.sheet.add.title)}</SheetTitle>
          <SheetDescription>{t(i18nKeys.global.settings.page.sites.sheet.add.description)}</SheetDescription>
        </SheetHeader>
        <SiteForm
          sites={sites}
          setOpenBomSheet={setOpenBomSheet}
          setSites={setSites}
          accountId={accountId}
          formType="bom"
        />
      </SheetContent>
    </Sheet>
  )
}

export default AddBomSite

import { EcoScore } from '@/client/backend/models'
import { i18nKeys } from '@/locales/keys'
import { useTranslation } from 'react-i18next'

import { Icons } from '@/components/icons'

const GlobalEcoscoreColor = ({ ecoscore_global }: EcoScore) => {
  const { t } = useTranslation()
  const iconClass =
    ecoscore_global === undefined
      ? 'text-gray-300'
      : ecoscore_global > 50 && ecoscore_global < 71
        ? 'text-green-500'
        : ecoscore_global > 20 && ecoscore_global < 51
          ? 'text-orange-500'
          : ecoscore_global >= 0 && ecoscore_global < 21
            ? 'text-red-500'
            : 'text-gray-300'

  const bgColor =
    ecoscore_global === undefined
      ? 'bg-gray-100'
      : ecoscore_global > 50 && ecoscore_global < 71
        ? 'bg-green-100'
        : ecoscore_global > 20 && ecoscore_global < 51
          ? 'bg-orange-100'
          : ecoscore_global >= 0 && ecoscore_global < 21
            ? 'bg-red-100'
            : 'bg-gray-100'

  const impactLevels: Record<number, string> = {
    10: i18nKeys.beeoimpact.common.impactLevel.high,
    5: i18nKeys.beeoimpact.common.impactLevel.medium,
    0: i18nKeys.beeoimpact.common.impactLevel.low,
  }

  return (
    <div className={`inline-flex size-8 items-center justify-center rounded-full ${bgColor}`}>
      <Icons.AlertOctagon className={`size-5 ${iconClass}`} />
      {ecoscore_global !== undefined && <span className="text-xs font-medium">{t(impactLevels[ecoscore_global])}</span>}
    </div>
  )
}

export default GlobalEcoscoreColor

import { CLCPlot, SpeciesSample } from '@/client/backend/models'
import { i18nKeys } from '@/locales/keys'
import { captureMessage } from '@sentry/browser'

import { Zone, ZoneWithCodesItem, ZoneWithPercentage } from '@/types/zone'

export interface ZoneNamesColor {
  zoneKey: string
  color: string
  name: string
  surface: number
  percentage: number
}

const zoneGroupKeys = {
  1: i18nKeys.beeoimpact.site.clcTypes.urban,
  2: i18nKeys.beeoimpact.site.clcTypes.agricultural,
  3: i18nKeys.beeoimpact.site.clcTypes.vegetation,
  4: i18nKeys.beeoimpact.site.clcTypes.wetAreas,
  5: i18nKeys.beeoimpact.site.clcTypes.waterBodies,
}

type IconMapping = {
  [key: number]: JSX.Element
}

export const getClcTableData = (clc: CLCPlot[]): ZoneNamesColor[] => {
  const content = clc.reduce((acc, curr) => {
    const firstDigit = curr.type.type_code_level1?.toString().charAt(0) ?? '0'
    const existingObj = acc.find((obj) => obj.name === curr.type.name_level3)

    if (existingObj) {
      existingObj.surface += (curr.intersection_area_sq_m ?? 0) / 10000
    } else {
      const newObj = {
        zoneKey: zoneGroupKeys[firstDigit],
        color: curr.type.color,
        name: curr.type.name_level3 ?? '',
        surface: (curr.intersection_area_sq_m ?? 0) / 10000,
        percentage: 0,
      }
      acc = [...acc, newObj]
    }
    return acc.sort((a, b) => b.surface - a.surface)
  }, [] as ZoneNamesColor[])

  const totalSurfaceArea = content.reduce((acc, curr) => {
    return acc + curr.surface
  }, 0)

  content.forEach((c) => {
    return (c.percentage = totalSurfaceArea !== 0 ? (c.surface / totalSurfaceArea) * 100 : 0)
  })

  return content
}

export const getZoneWithCodes = (clc: CLCPlot[], siteId?: string): Zone => {
  const zoneWithCodes: Zone = clc.reduce((acc, data) => {
    if (!data.type.type_code_level1) {
      captureMessage(`Error in CLC data for site ${siteId}`)
      return acc
    }

    const category = data.type.type_code_level1?.toString().charAt(0)

    const obj = {
      zoneKey: zoneGroupKeys[category],
      area: (data.intersection_area_sq_m ?? 0) * 10000 || 0,
      category: category,
      codes: [data.type.type_code_level1?.toString()],
    } as ZoneWithCodesItem

    if (!acc[category]) {
      acc[category] = [obj]
      acc[category].total_surface = obj.area
    } else {
      acc[category].push(obj)
      acc[category].total_surface += obj.area
    }
    acc[category].key = category
    return acc
  }, {} as Zone)

  return zoneWithCodes
}

const getTotalSurface = (zoneWithCodes: Zone): number =>
  Object.values(zoneWithCodes)?.reduce((acc, curr) => acc + curr?.total_surface, 0)

export const getZoneWithPercentage = (zoneWithCodes: Zone, iconMapping: IconMapping): ZoneWithPercentage[] => {
  const totalSurface = getTotalSurface(zoneWithCodes)

  return Object.entries(zoneWithCodes)
    .map(([key, value]) => {
      const icon = iconMapping[parseInt(key)]
      const descriptionKey = zoneGroupKeys[parseInt(value.key)]
      const percentage = (value.total_surface / totalSurface) * 100
      return {
        key,
        icon,
        percentage,
        zone: value[0]?.zone,
        descriptionKey,
      } as ZoneWithPercentage
    })
    .sort((a, b) => b.percentage - a.percentage)
}

export const getThreatenedSpeciesNumber = (gbif?: SpeciesSample[]): number =>
  gbif?.filter(
    (species) => species.iucn_status_eu === 'VU' || species.iucn_status_eu === 'EN' || species.iucn_status_eu === 'CR'
  ).length ?? 0

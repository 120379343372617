import { Dispatch, SetStateAction, useMemo } from 'react'
import { SensitiveAreaOut, Site } from '@/client/backend/models'
import { LatLng } from 'leaflet'
import { Circle, LayerGroup, LayersControl, MapContainer, Marker, ScaleControl, TileLayer } from 'react-leaflet'

import { DEFAULT_RADIUS, getBoundsFromCenterAndRadius } from '@/lib/geo'
import CardWithMapCdda from '@/components/beeoimpact/impacts/card-with-map-cdda'
import CardWithMapN2000 from '@/components/beeoimpact/impacts/card-with-map-n2000'
import MapBadge from '@/components/beeoimpact/impacts/map-badge'

type SiteProtectedZonesMapProps = {
  protectedAreas: SensitiveAreaOut[]
  designatedAreas: SensitiveAreaOut[]
  center: LatLng
  radius?: number
  setSelectedPolygonId: Dispatch<SetStateAction<number>>
  selectedPolygonId: number
  setSelectedProtectedAreaId: Dispatch<SetStateAction<number>>
  selectedProtectedAreaId: number
  clickRow: number
  setClickRow: Dispatch<SetStateAction<number>>
  clickRowSpecies: number
  setClickRowSpecies: Dispatch<SetStateAction<number>>
  site: Site
}
const SensitiveAreasTableMap = ({
  protectedAreas,
  designatedAreas,
  center,
  radius,
  selectedPolygonId,
  setSelectedPolygonId,
  setSelectedProtectedAreaId,
  clickRow,
  setClickRow,
  clickRowSpecies,
  setClickRowSpecies,
  site,
}: SiteProtectedZonesMapProps) => {
  const mapRadius = radius ?? DEFAULT_RADIUS
  const mapBounds = useMemo(() => {
    return getBoundsFromCenterAndRadius(center, mapRadius)
  }, [center, mapRadius])

  const onToggleRow = (clickMap) => {
    setSelectedPolygonId((prevClickRow) => (prevClickRow === clickMap ? 0 : clickMap))
  }

  const onToggleSpecies = (clickMap: number) => {
    setSelectedProtectedAreaId((prevClickRow) => (prevClickRow === clickMap ? 0 : clickMap))
  }
  return (
    <div>
      <MapContainer
        className="z-0 h-[30rem] rounded-md"
        bounds={mapBounds}
        zoomSnap={0.1}
        scrollWheelZoom={false}
        attributionControl={false}
      >
        <Circle center={center} color={'cadetblue'} fillColor="cadetblue" radius={mapRadius} />
        <TileLayer key="tile" url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
        <MapBadge content={`${((site?.properties?.biodiversity_radius ?? 0) / 1000).toFixed(1)}`} />
        <Marker position={center} />
        <LayersControl position="bottomright">
          <>
            <LayersControl.Overlay checked name="CDDA">
              <LayerGroup>
                <CardWithMapCdda
                  designatedAreas={designatedAreas}
                  onToggleRow={onToggleRow}
                  setSelectedPolygonId={setSelectedPolygonId}
                  setSelectedProtectedAreaId={onToggleSpecies}
                  clickRow={clickRow}
                  setClickRow={setClickRow}
                  selectedPolygonId={selectedPolygonId}
                />
              </LayerGroup>
            </LayersControl.Overlay>
            <LayersControl.Overlay checked name="Natura 2000">
              <LayerGroup>
                <CardWithMapN2000
                  onToggleRow={onToggleRow}
                  protectedAreas={protectedAreas}
                  selectedPolygonId={selectedPolygonId}
                  setSelectedPolygonId={setSelectedPolygonId}
                  setSelectedProtectedAreaId={onToggleSpecies}
                  clickRow={clickRow}
                  setClickRow={setClickRow}
                  clickRowSpecies={clickRowSpecies}
                  setClickRowSpecies={setClickRowSpecies}
                />
              </LayerGroup>
            </LayersControl.Overlay>
          </>
        </LayersControl>
        <ScaleControl position="bottomleft" />
      </MapContainer>
    </div>
  )
}

export default SensitiveAreasTableMap

import { PlotCulture, ProbablePesticide, SiteGeometry } from '@/client/backend/models'
import { i18nKeys } from '@/locales/keys'
import { LatLng } from 'leaflet'
import { useTranslation } from 'react-i18next'
import { Circle, MapContainer, Pane, ScaleControl, TileLayer } from 'react-leaflet'

import { DEFAULT_RADIUS, getBounds } from '@/lib/geo'
import { buildColors } from '@/lib/parcels'
import { toTitleCase } from '@/lib/string'
import MapBadge from '@/components/beeoimpact/impacts/map-badge'
import SiteParcelsPesticides from '@/components/beeoimpact/pesticides/site-parcels-pesticides-details'

interface SiteMapProps {
  area?: SiteGeometry
  selectedPoint: LatLng
  landplots: PlotCulture[]
  selectedPesticide?: ProbablePesticide
}

const PesticidesTableMap = ({ area, selectedPoint, landplots, selectedPesticide }: SiteMapProps) => {
  const { t } = useTranslation()

  const uniqueCulture = [
    ...new Set(
      landplots.map((plot) => {
        return plot.culture
      })
    ),
  ]

  const colors = buildColors(uniqueCulture)
  return (
    <>
      <MapContainer
        zoom={13.9}
        zoomSnap={0.1}
        className="z-0 h-[30rem]"
        center={selectedPoint}
        bounds={getBounds(area?.coordinates?.[0])}
        scrollWheelZoom={false}
        attributionControl={false}
      >
        <TileLayer key="tile" url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
        <MapBadge content={`${(DEFAULT_RADIUS / 1000).toFixed(1)}`} />
        <Pane name="textPane">
          {selectedPesticide && (
            <div
              style={{
                width: '300px',
                height: '50px',
                position: 'absolute',
                top: '20%',
                left: '60%',
                transform: 'translate(40%, 800%)',
                backgroundColor: 'whitesmoke',
                padding: '11px',
                borderRadius: '5px',
                border: '2px solid red',
                borderColor: 'cadetblue',
                color: 'cadetblue',
              }}
            >
              <div className="flex flex-row items-center space-x-1">
                {selectedPesticide && <p className="text-base"> {toTitleCase(selectedPesticide.name)} :</p>}
                <p className="text-base">{t(i18nKeys.beeoimpact.pesticidesTable.map.possibleOrigin)}</p>
              </div>
            </div>
          )}
        </Pane>
        {landplots?.map((landplot) => (
          <SiteParcelsPesticides
            key={landplot.id}
            landplot={landplot}
            colors={colors}
            selectedPesticide={selectedPesticide}
          />
        ))}
        <Circle center={selectedPoint} radius={DEFAULT_RADIUS} color={'cadetblue'} fillColor="cadetblue" />
        <ScaleControl position="bottomleft" />
      </MapContainer>
    </>
  )
}

export default PesticidesTableMap

import { SensitiveAreaOut, Site } from '@/client/backend/models'
import { i18nKeys } from '@/locales/keys'
import { LatLng, PathOptions } from 'leaflet'
import { useTranslation } from 'react-i18next'
import { Circle, LayerGroup, LayersControl, MapContainer, Polygon, ScaleControl, TileLayer } from 'react-leaflet'

import { convertGeoJsonToGeometry, DEFAULT_RADIUS, GeoJsonArea, getBoundsFromCenterAndRadius } from '@/lib/geo'
import { Icons } from '@/components/icons'

interface RiskMapReportProps extends React.HTMLAttributes<HTMLDivElement> {
  center: LatLng
  site: Site
  n2k: SensitiveAreaOut[]
  cdda: SensitiveAreaOut[]
  onMapReady?: () => void
}

const RiskMapReport = ({ n2k, cdda, site, center, className, onMapReady, ...props }: RiskMapReportProps) => {
  const { t } = useTranslation()

  const biodiversityMapRadius = site.properties?.biodiversity_radius ?? DEFAULT_RADIUS
  const biodiversityMapBounds = getBoundsFromCenterAndRadius(center, biodiversityMapRadius)

  function renderAreas() {
    const createPolygonOptions = (): PathOptions => ({
      color: 'white',
      fillColor: 'orange',
      fillOpacity: 0.5,
      weight: 1,
      opacity: 0.5,
    })

    const areas = !n2k
      ? []
      : n2k.map((protectedArea, idx) => {
          const options = createPolygonOptions()
          const newGeom = convertGeoJsonToGeometry(protectedArea.intersection as GeoJsonArea)

          if (!newGeom) {
            return null
          }

          return <Polygon key={idx} pathOptions={options} positions={newGeom} pane="markerPane" className="z-0" />
        })

    return areas
  }

  function renderCddas() {
    const createPolygonOptions = (): PathOptions => ({
      color: 'white',
      fillColor: 'darkblue',
      fillOpacity: 0.5,
      weight: 1,
      opacity: 0.5,
    })
    const nationallyDesignatedAreas = !cdda
      ? []
      : cdda.map((designatedArea, idx) => {
          const options = createPolygonOptions()
          const newGeom = convertGeoJsonToGeometry(designatedArea.intersection as GeoJsonArea)

          if (!newGeom) {
            return null
          }

          return <Polygon key={idx} pathOptions={options} positions={newGeom} pane="markerPane" className="z-0" />
        })

    return nationallyDesignatedAreas
  }

  const biodivScore = (site?.properties?.stats_biodiversity?.biodiversity_score ?? 0).toFixed(2) + ' %'

  return (
    <div className={className} {...props}>
      {!(n2k.length === 0 && cdda.length === 0 && parseInt(biodivScore) !== 0) ? (
        <>
          <div className="size-96">
            <MapContainer
              className="z-0 size-96"
              bounds={biodiversityMapBounds}
              zoomSnap={0.1}
              scrollWheelZoom={false}
              whenReady={onMapReady}
            >
              <Circle center={center} color={'cadetblue'} fillColor="cadetblue" radius={biodiversityMapRadius} />
              <TileLayer key="tile" url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
              <LayersControl position="topright">
                <LayersControl.Overlay checked name="CDDA">
                  <LayerGroup>{renderCddas()}</LayerGroup>
                </LayersControl.Overlay>
                <LayersControl.Overlay checked name="Natura 2000">
                  <LayerGroup>{renderAreas()}</LayerGroup>
                </LayersControl.Overlay>
              </LayersControl>
              <ScaleControl position="topleft" />
            </MapContainer>
          </div>
          <div>
            <div className="flex flex-col justify-evenly space-y-4">
              <div className="flex flex-row space-x-4">
                <p>
                  <Icons.Leaf />
                </p>
                <div>
                  <p>{biodivScore}</p>
                  <p className="text-muted-foreground">
                    {t(i18nKeys.beeoimpact.site.biodiversityAround.biodiversityScore)}
                  </p>
                </div>
              </div>
              <div className="flex flex-row space-x-4">
                <p>
                  <Icons.Trees />
                </p>
                <div>
                  <p>
                    {t(i18nKeys.beeoimpact.site.biodiversityAround.sensitiveAreasIn, {
                      count: site.properties?.stats_biodiversity?.nb_in_sensitive_area,
                    })}
                  </p>
                  <p className="text-muted-foreground">
                    {t(i18nKeys.beeoimpact.site.biodiversityAround.sensitiveAreaInDef)}
                  </p>
                </div>
              </div>
              <div className="flex flex-row space-x-4">
                <p>
                  <Icons.Shrub />
                </p>
                <div>
                  <p>
                    {t(i18nKeys.beeoimpact.site.biodiversityAround.influenceZoneAdj, {
                      count: site.properties?.stats_biodiversity?.nb_adjacent_sensitive_area,
                    })}
                  </p>
                  <p className="text-muted-foreground">
                    {t(i18nKeys.beeoimpact.site.biodiversityAround.influenceZoneAdjDef)}
                  </p>
                </div>
              </div>
              <div className="flex flex-row space-x-4">
                <Icons.TreeDeciduous />
                <div>
                  <p>
                    {t(i18nKeys.beeoimpact.site.biodiversityAround.closestReserve)}{' '}
                    {site?.properties?.stats_biodiversity?.closest_reserve?.distance_m?.toFixed(2)} m
                  </p>
                  <p className="text-muted-foreground">
                    {t(i18nKeys.beeoimpact.site.biodiversityAround.closestReserveDef)}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <div className="container mt-4 flex h-80 items-center justify-center rounded-md border border-dashed">
          <div className="mx-auto flex max-w-[420px] flex-col items-center justify-center text-center">
            <Icons.Leaf size={42} />
            <h3 className="mt-4 text-lg font-semibold">
              {t(i18nKeys.beeoimpact.site.biodiversityAround.noSensitiveAreasFound)}
            </h3>
            <div className="mb-4 mt-2 text-sm text-muted-foreground">
              <p>
                {t(i18nKeys.beeoimpact.site.biodiversityAround.noSensitiveAreas)}{' '}
                {site?.properties?.biodiversity_radius} {t(i18nKeys.beeoimpact.site.measurementUnit)}
              </p>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default RiskMapReport

import { FC } from 'react'

const Loader: FC<{ status?: string }> = ({ status }) => {
  return (
    <div className="absolute top-0 flex size-full flex-col items-center justify-center">
      <div className="size-32 animate-spin rounded-full border-y-2 border-gray-900"></div>
      {status && <p className="mt-4 text-center">{status}</p>}
    </div>
  )
}

export default Loader

import { SensitiveAreaOut } from '@/client/backend/models'
import { LatLngExpression, PathOptions } from 'leaflet'
import { Polygon, Tooltip } from 'react-leaflet'

import { convertGeoJsonToGeometry } from '@/lib/geo'

interface CardWithMapN2000Props {
  protectedAreas: SensitiveAreaOut[]
  selectedPolygonId: number
  setSelectedPolygonId: (value: number) => void
  onToggleRow: (value: LatLngExpression[] | LatLngExpression[][]) => void
  setSelectedProtectedAreaId: (value: number) => void
  clickRow: number
  setClickRow: (value: number) => void
  clickRowSpecies: number
  setClickRowSpecies: (value: number) => void
}

const CardWithMapN2000 = ({
  onToggleRow,
  protectedAreas,
  selectedPolygonId,
  setSelectedPolygonId,
  setSelectedProtectedAreaId,
  clickRow,
  setClickRow,
  clickRowSpecies,
  setClickRowSpecies,
}: CardWithMapN2000Props) => {
  const areas =
    protectedAreas === null
      ? []
      : protectedAreas.map((protectedArea, idx) => {
          const isSelectedN2000 = protectedArea.id === selectedPolygonId
          const isClickedRowN2000 = protectedArea.id === clickRow
          const isSpeciesRowClicked = protectedArea.speciesfound.find((species) => species.id === clickRowSpecies)
          const options: PathOptions = {
            color: isSelectedN2000 ? 'black' : isClickedRowN2000 ? 'black' : isSpeciesRowClicked ? 'black' : '',
            fillColor: 'orange',
            fillOpacity: 0.5,
            weight: isSelectedN2000 ? 2 : isClickedRowN2000 ? 2 : isSpeciesRowClicked ? 2 : undefined,
            opacity: isSelectedN2000 ? 2 : isClickedRowN2000 ? 2 : isSpeciesRowClicked ? 2 : undefined,
          }

          const newGeom = convertGeoJsonToGeometry(protectedArea.geom)

          if (!newGeom) {
            return null
          }
          return (
            <Polygon
              key={idx}
              pathOptions={options}
              positions={newGeom}
              className="outline-none"
              pane="markerPane"
              eventHandlers={{
                click: () => {
                  setSelectedPolygonId(isSelectedN2000 ? 0 : protectedArea.id)
                  onToggleRow(newGeom)
                  setSelectedPolygonId(0)
                  setSelectedProtectedAreaId(isSelectedN2000 ? 0 : protectedArea.id)
                  setClickRow(isClickedRowN2000 ? 0 : protectedArea.id)
                  setClickRowSpecies(isSpeciesRowClicked ? 0 : protectedArea.id)
                },
              }}
            >
              <Tooltip sticky>
                <ul>
                  <li>{protectedArea.name}</li>
                </ul>
              </Tooltip>
            </Polygon>
          )
        })

  return areas
}

export default CardWithMapN2000

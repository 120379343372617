import { Info } from 'lucide-react'

import { cn } from '@/lib/utils'
import { Card, CardContent, CardHeader } from '@/components/ui/card'
import { Skeleton } from '@/components/ui/skeleton'
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '@/components/ui/tooltip'

interface CardAnalyticsProps {
  description?: React.ReactNode
  icon: React.ReactNode
  value?: number | string
  unit?: string
  label: string
  iconBgColor?: string
  total?: number
  isLoading?: boolean
}

const CardAnalytics = ({
  description,
  icon,
  label,
  value,
  unit,
  iconBgColor = '',
  total,
  isLoading,
}: CardAnalyticsProps) => {
  if (isLoading) {
    return <Skeleton className="h-[232px] w-full" />
  }

  return (
    <Card className="h-full">
      <CardHeader className="h-10">
        {description && (
          <TooltipProvider>
            <Tooltip>
              <TooltipTrigger asChild>
                <div className="flex justify-end">
                  <Info className={cn('size-5')} />
                </div>
              </TooltipTrigger>
              <TooltipContent data-side="bottom" className="w-96">
                {description}
              </TooltipContent>
            </Tooltip>
          </TooltipProvider>
        )}
      </CardHeader>
      <CardContent>
        <div className="flex items-center justify-center">
          <div className={cn('flex size-10 items-center justify-center rounded-full', iconBgColor)}>{icon}</div>
        </div>

        <div className="flex items-center justify-center py-4">
          <span className="text-5xl font-bold">
            {value} {unit}
          </span>
        </div>
        <div className="flex items-center justify-center">
          {total && total > 0 && (
            <div className="flex items-center justify-center">
              <div
                className={cn(
                  'mr-1 flex size-6 items-center justify-center rounded-full text-xs text-muted-foreground',
                  iconBgColor
                )}
              >
                {total}
              </div>
            </div>
          )}
          <p className="text-sm text-muted-foreground">{label}</p>
        </div>
      </CardContent>
    </Card>
  )
}

export default CardAnalytics

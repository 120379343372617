import { i18nKeys } from '@/locales/keys'
import { useTranslation } from 'react-i18next'

import { SubSpecies } from '@/lib/n2k'

import { Table, TableCell, TableHeaderCell, TableRow } from './table.print'

interface PrioritySpeciesTableProps {
  data: SubSpecies[]
}

const PrioritySpeciesTablePrint = ({ data }: PrioritySpeciesTableProps) => {
  const { t } = useTranslation()

  return (
    <Table
      columns={5}
      header={
        <>
          <TableHeaderCell>{t(i18nKeys.beeoimpact.biodiversityTable.biodiversityReserves.table.name)}</TableHeaderCell>
          <TableHeaderCell>{t(i18nKeys.beeoimpact.biodiversityTable.biodiversityReserves.table.type)}</TableHeaderCell>
        </>
      }
    >
      {data?.map(({ name, vernacular_name, group }, i) => {
        return (
          <TableRow key={i}>
            <TableCell>
              <div className="flex flex-col">
                <span className="font-medium">{name}</span>
                <span className="text-xs text-muted-foreground">{vernacular_name}</span>
              </div>
            </TableCell>
            <TableCell>
              <span>{group}</span>
            </TableCell>
          </TableRow>
        )
      })}
    </Table>
  )
}

export default PrioritySpeciesTablePrint
